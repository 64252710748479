import { FontWeight, Color } from 'theme/style'

export default {
  day: {
  },
  daySelected: {
    '& p': {
      color: Color.White,
      fontWeight: FontWeight.Medium,
      backgroundColor: Color.Primary
    }
  },
  current: {
    border: '2px solid',
    borderColor: Color.Primary,
    '& p': {
      color: Color.Primary,
      fontWeight: FontWeight.Medium,
    }
  }
};
