import React, { useEffect } from 'react'
import { Input, InputBaseProps, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { Color, FontWeight, Value } from 'theme/style'
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { config } from 'app/config'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  inputContainer: {
    display: 'flex',
  },
  input: {
    flex: 1,
    backgroundColor: Color.Background,
    padding: theme.spacing(0, 1.5),
    '& input': {
      cursor: 'pointer !important',
      padding: theme.spacing(0),
      textAlign: 'left',
      height: '100%',
    },
  },
}))

interface DateFieldProps {
  className?: string
  name: string
  value: Date
  type?: 'date' | 'time' | 'datetime'
  onUpdate?: (name: string, value: any) => void
  onConfirm?: (name: string, value: any) => void
}
const DateField = ({ ...props }: DateFieldProps) => {
  const classes = useStyles()



  //FIELD CHANGES

  function updateField(date: any) {
    const value = date

    if (props.onUpdate == null) return
    props.onUpdate(props.name, value)
  }



  //RENDER

  return (
    <div className={classes.inputContainer}>
      {(props.type === 'datetime' || props.type == null) &&
        <DateTimePicker
          {...props}
          autoOk
          className={clsx(classes.input, props.className)}
          variant="inline"
          format={config.app.defaultDateTimeFormat}
          value={props.value}
          onChange={date => updateField(date)}
        />
      }

      {props.type === 'date' &&
        <DatePicker
          disableToolbar
          autoOk
          className={clsx(classes.input, props.className)}
          variant="inline"
          format={config.app.defaultDateFormat}
          value={props.value}
          onChange={date => updateField(date)}
        />
      }

    </div>
  )
}

export default DateField