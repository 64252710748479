import { Card, makeStyles } from '@material-ui/core'
import { Language } from 'app/entities/types'
import { CardContent, CardHeader, CardField, FieldsListErrors, PageEntityDetailsRefreshableProps, useLocalization } from 'components'
import {
  getPageMeta, GetPageMetaRequestParams, GetPageMetaResponse,
  updatePageMeta, UpdatePageMetaRequestParams
} from 'app/api';
import React, { useState, useEffect } from 'react'
import { toast } from 'app/utils';
import _ from 'lodash';
import clone from 'lodash/cloneDeep';
import { processFieldsErrors, fieldHasError, fieldsHaveError } from 'components/_CardPartials/CardField/methods'
import clsx from 'clsx'
import { Color } from 'theme/style'

enum FieldName {
  DesktopMetaTitle = 'desktopMetaTitle',
  DesktopMetaDescription = 'desktopMetaDescription',
  MobileMetaTitle = 'mobileMetaTitle',
  MobileMetaDescription = 'mobileMetaDescription',
}

type DataProps = { [key: string]: string | null }
type SEOScoreType = 'bad' | 'mediocre' | 'good'

interface InformationProps extends PageEntityDetailsRefreshableProps {
  pageId: number
  language: Language
}

const Information = ({ ...props }: InformationProps) => {
  const { t } = useLocalization()

  const [isLoading, setIsLoading] = useState(true)
  const [translationId, setTranslationId] = useState<number | null>(null)



  //DATA

  const [data, setData] = useState<DataProps>({
    [FieldName.DesktopMetaTitle]: null,
    [FieldName.DesktopMetaDescription]: null,
    [FieldName.MobileMetaTitle]: null,
    [FieldName.MobileMetaDescription]: null,
  })
  const [currentData, setCurrentData] = useState(data)
  const [isUpdatingCurrentData, setIsUpdatingCurrentData] = useState(false)

  function updateData(data: DataProps) {
    setIsUpdatingCurrentData(true)
    setData(data)
    setCurrentData(data)
  }

  useEffect(() => {
    setIsUpdatingCurrentData(false)
  }, [currentData])

  useEffect(() => {
    const initialized = translationId != null
    setIsLoading(!initialized)
  }, [translationId])



  //ERROR HANDLING

  const titleCharacterLimit = 100
  const descriptionCharacterLimit = 255

  const errorsConditions: FieldsListErrors = {
    [FieldName.DesktopMetaTitle]: [
      {
        error: data.desktopMetaTitle != null && data.desktopMetaTitle.length > titleCharacterLimit,
        errorMessage: t('common.page.tab.page-seo.metatags.title-desktop.error.limit', { tags: { 'COUNT': titleCharacterLimit } })
      }
    ],
    [FieldName.DesktopMetaDescription]: [
      {
        error: data.desktopMetaDescription != null && data.desktopMetaDescription.length > descriptionCharacterLimit,
        errorMessage: t('common.page.tab.page-seo.metatags.description-desktop.error.limit', { tags: { 'COUNT': descriptionCharacterLimit } })
      }
    ],
    [FieldName.MobileMetaTitle]: [
      {
        error: data.mobileMetaTitle != null && data.mobileMetaTitle.length > titleCharacterLimit,
        errorMessage: t('common.page.tab.page-seo.metatags.title-mobile.error.limit', { tags: { 'COUNT': titleCharacterLimit } })
      }
    ],
    [FieldName.MobileMetaDescription]: [
      {
        error: data.mobileMetaDescription != null && data.mobileMetaDescription.length > descriptionCharacterLimit,
        errorMessage: t('common.page.tab.page-seo.metatags.description-mobile.error.limit', { tags: { 'COUNT': descriptionCharacterLimit } })
      }
    ]
  }

  const [errors, setErrors] = useState<FieldsListErrors>({})

  useEffect(() => {
    setErrors(processFieldsErrors(FieldName, errorsConditions))
  }, [data])

  function checkFieldErrorStatus(name: FieldName) {
    return fieldHasError(name, errorsConditions)
  }



  //FIELD UPDATES

  function updateFieldValue(name: string, value: any) {
    const temp = clone(data)

    switch (name) {
      case FieldName.DesktopMetaTitle: temp.desktopMetaTitle = value; break
      case FieldName.DesktopMetaDescription: temp.desktopMetaDescription = value; break
      case FieldName.MobileMetaTitle: temp.mobileMetaTitle = value; break
      case FieldName.MobileMetaDescription: temp.mobileMetaDescription = value; break
      default: break
    }

    setData(temp)
  }

  function confirmFieldValue(name: string, value: any) {
    save(name as FieldName)
  }



  //SAVE

  function saveIsAllowed(name?: FieldName): boolean {
    if (isUpdatingCurrentData === true) return false
    if (name != null) {
      if (checkFieldErrorStatus(name) === true) return false
      if (data[name] === currentData[name]) return false
    }
    else {
      if (fieldsHaveError(errorsConditions) === true) return false
    }
    return true
  }

  function prepareForSave(name?: FieldName) {
    const temp = _.clone(currentData) as any
    if (name != null) temp[name] = data[name]
    setCurrentData(temp)
  }

  function save(name?: FieldName) {
    if (saveIsAllowed(name) === false) return
    prepareForSave(name)

    performSave()

    function performSave() {
      if (translationId == null) return

      const encode = (): UpdatePageMetaRequestParams => {
        return {
          id: translationId,
          metaTitleDesktop: data.desktopMetaTitle,
          metaDescriptionDesktop: data.desktopMetaDescription,
          metaTitleMobile: data.mobileMetaTitle,
          metaDescriptionMobile: data.mobileMetaDescription,
        }
      }

      updatePageMeta(encode(), {
        response(data) {
          toast.success(t('alert.page-data.metatags.update'))
          if (props.refreshObject != null) props.refreshObject()
        },
        error(error, message) {
          toast.error(message)
        }
      })
    }
  }



  //TRANSLATION

  useEffect(() => {
    const fetchMetaData = () => {
      const encode = (): GetPageMetaRequestParams => {
        return {
          pageId: props.pageId,
          languageId: props.language.id,
        }
      }
      const decode = (data: GetPageMetaResponse) => {
        return {
          [FieldName.DesktopMetaTitle]: data.pageTranslation.meta.desktop.metaTitleDesktop,
          [FieldName.DesktopMetaDescription]: data.pageTranslation.meta.desktop.metaDescriptionDesktop,
          [FieldName.MobileMetaTitle]: data.pageTranslation.meta.mobile.metaDescriptionMobile,
          [FieldName.MobileMetaDescription]: data.pageTranslation.meta.mobile.metaDescriptionMobile,
        }
      }

      setIsLoading(true)
      getPageMeta(encode(), {
        response(data) {
          setTranslationId(data.pageTranslation.id)

          const object = decode(data)
          updateData(object)
        },
        error(error, message) {
          toast.error(message)
        }
      })
    }

    fetchMetaData()
  }, [props.language])



  //SEO SCORES

  const [seoScores, setSeoScores] = useState<{ [key: string]: SEOScoreType }>({
    [FieldName.DesktopMetaTitle]: 'bad',
    [FieldName.DesktopMetaDescription]: 'bad',
    [FieldName.MobileMetaTitle]: 'bad',
    [FieldName.MobileMetaDescription]: 'bad',
  })

  function seoScoreConditions(name: string): SEOScoreType {
    switch (name) {
      case FieldName.DesktopMetaTitle:
        if (data.desktopMetaTitle != null) {
          if (data.desktopMetaTitle.length <= 20) return 'bad'
          else if (data.desktopMetaTitle.length <= 70) return 'mediocre'
          else if (data.desktopMetaTitle.length <= 100) return 'good'
        }
        break

      case FieldName.DesktopMetaDescription:
        if (data.desktopMetaDescription != null) {
          if (data.desktopMetaDescription.length <= 60) return 'bad'
          else if (data.desktopMetaDescription.length <= 180) return 'mediocre'
          else if (data.desktopMetaDescription.length <= 255) return 'good'
        }
        break

      case FieldName.MobileMetaTitle:
        if (data.mobileMetaTitle != null) {
          if (data.mobileMetaTitle.length <= 20) return 'bad'
          else if (data.mobileMetaTitle.length <= 70) return 'mediocre'
          else if (data.mobileMetaTitle.length <= 100) return 'good'
        }
        break

      case FieldName.MobileMetaDescription:
        if (data.mobileMetaDescription != null) {
          if (data.mobileMetaDescription.length <= 60) return 'bad'
          else if (data.mobileMetaDescription.length <= 180) return 'mediocre'
          else if (data.mobileMetaDescription.length <= 255) return 'good'
        }
        break
    }
    return 'bad'
  }

  useEffect(() => {
    let obj: any = {}
    Object.values(FieldName).forEach((type: string) => {
      let score = seoScoreConditions(type)
      obj[type] = score
    })
    setSeoScores(obj)
  }, [data])



  //RENDER

  return (
    <>
      <Card>
        <CardHeader title={t('common.page.tab.page-seo.metatags.title')} isLoading={isLoading} />
        <CardContent isLoading={isLoading} >

          <CardField type={'text'} name={FieldName.DesktopMetaTitle}
            label={t('common.page.tab.page-seo.metatags.title-desktop.label')} placeholder={t('common.page.tab.page-seo.metatags.title-desktop.placeholder')}
            value={data.desktopMetaTitle ?? ''} errors={errors}
            onUpdate={updateFieldValue} onConfirm={confirmFieldValue}
            endIcon={<SEOScoreIcon score={seoScores.desktopMetaTitle} />}
          />

          <CardField type={'textarea'} name={FieldName.DesktopMetaDescription}
            label={t('common.page.tab.page-seo.metatags.description-desktop.label')} placeholder={t('common.page.tab.page-seo.metatags.description-desktop.placeholder')}
            value={data.desktopMetaDescription ?? ''} errors={errors}
            onUpdate={updateFieldValue} onConfirm={confirmFieldValue}
            endIcon={<SEOScoreIcon score={seoScores.desktopMetaDescription} />}
          />

          <CardField type={'text'} name={FieldName.MobileMetaTitle}
            label={t('common.page.tab.page-seo.metatags.title-mobile.label')} placeholder={t('common.page.tab.page-seo.metatags.title-mobile.placeholder')}
            value={data.mobileMetaTitle ?? ''} errors={errors}
            onUpdate={updateFieldValue} onConfirm={confirmFieldValue}
            endIcon={<SEOScoreIcon score={seoScores.mobileMetaTitle} />}
          />

          <CardField type={'textarea'} name={FieldName.MobileMetaDescription}
            label={t('common.page.tab.page-seo.metatags.description-mobile.label')} placeholder={t('common.page.tab.page-seo.metatags.description-mobile.placeholder')}
            value={data.mobileMetaDescription ?? ''} errors={errors}
            onUpdate={updateFieldValue} onConfirm={confirmFieldValue}
            endIcon={<SEOScoreIcon score={seoScores.mobileMetaDescription} />}
          />
        </CardContent>
      </Card>
    </>
  )
}

export default Information





type SEOScoreIconProps = {
  score: SEOScoreType
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: 15,
    height: 15,
    borderRadius: '50%',
    overflow: 'hidden',
    '& >div': {
      width: 15,
      height: 15,
    }
  },
  veryBad: {
    backgroundColor: '#878787'
  },
  bad: {
    backgroundColor: Color.Error
  },
  good: {
    backgroundColor: Color.Warning
  },
  veryGood: {
    backgroundColor: Color.Success
  },
}))

const SEOScoreIcon = ({ ...props }: SEOScoreIconProps) => {
  const classes = useStyles()

  function scoreClass(): string {
    switch (props.score) {
      case 'bad': return classes.bad
      case 'mediocre': return classes.good
      case 'good': return classes.veryGood
    }
  }

  function scoreIcon(): React.ReactNode {
    switch (props.score) {
      case 'bad': return <div />
      case 'mediocre': return <div />
      case 'good': return <div />
    }
  }
  return (
    <div className={clsx(classes.root, scoreClass())}>{scoreIcon()}</div>
  )
}