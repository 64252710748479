import { UserRoleValue, UserPermissionValue } from 'app/values'
import { User } from 'app/entities/types'
import { userRoleIs, userRoleHasPermission } from 'app/entities/methods'
import { auth } from 'app/auth'

export function userIs(u: User, r: UserRoleValue): boolean {
  return userRoleIs(u.role, r) === true
}

export function userCan(u: User, p: UserPermissionValue): boolean {
  return userRoleHasPermission(u.role, p)
}

export function userHasAccessToRoute(user: User, route: string) {
  if (route == null) return true

  let allowed = true
  const wildcard = '*'

  auth.forEach(a => {
    a.availableRoutes.forEach(r => {
      if (r.endsWith(wildcard) === true) {
        let temp = r.slice(0, -1)
        if (allowed === true && route.includes(temp) === true) {
          temp = route.replace(temp, '')
          if (temp.length !== 0) allowed = userCan(user, a.permission) === true
        }
      }
      else {
        if (allowed === true && route.includes(r) === true) {
          allowed = userCan(user, a.permission) === true
        }
      }
    })
  })
  return allowed
}

export function getUserName(u: User): string {
  return u.firstname + ' ' + u.lastname
}
