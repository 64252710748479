
export enum Color {

  //Main palette.
  Black = '#000000',
  White = '#FFFFFF',
  Background = '#F4F6F9',
  Primary = '#126bc7',
  Secondary = '#03c08f',
  Success = '#36C2A1',
  Warning = '#fdbe1d',
  Error = '#E02B46',
  Info = '#2196f3',
  TextPrimary = '#454867',
  TextSecondary = '#707282',
  TextDisabled = '#d4dce4',
  TextHighlight = '#fcdf95',
  Notification = '#ff0000',

  //Elements.
  Sidebar = '#126bc7',
  SidebarDark = '#105EAC',
  Divider = '#eff1f4',

  //Tags.
  TagErrorBackground = '#F76A6A',
  TagErrorText = '#FFFFFF',

  TagSuccessBackground = '#67D0A2',
  TagSuccessText = '#FFFFFF',

  TagWarningBackground = '#FACD59',
  TagWarningText = '#FFFFFF',

  TagAlertBackground = '#FE945B',
  TagAlertText = '#FFFFFF',

  TagInfoBackground = '#31b7f5',
  TagInfoText = '#FFFFFF',

}

