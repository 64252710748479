import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import LocalPharmacyIcon from '@material-ui/icons/LocalPharmacy'
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered'
import DescriptionIcon from '@material-ui/icons/Description';

export default [
  {
    title: 'sidebar.title.website', pages: [
      { title: 'sidebar.item.pharmacies', icon: LocalPharmacyIcon, href: '/app/pharmacies' },
      { title: 'sidebar.item.zentiva-contacts', icon: AssignmentIndIcon, href: '/app/zentiva-contacts' },
      { title: 'sidebar.item.trainings', icon: FormatListNumberedIcon, href: '/app/tasks' },
      { title: 'sidebar.item.guide', icon: DescriptionIcon, href: '/guide/rep' },
    ]
  },
]