import executeRequest, { RequestOutput } from 'app/api'
import { Currency } from 'app/entities/types'
import { APIPaginatable, APIPaginatableResponse, APISortable, APISearchable, APIDateFilterable } from 'app/api/types'

enum APIEndpoint {
  GetAllOrders = 'api/orders',
  CreateOrder = 'api/orders/create',
  GetOrderById = 'api/orders/getById',
  UpdateOrder = 'api/orders/update',
  AddOrderItem = 'api/orders/addItem',
  UpdateOrderItem = 'api/orders/updateItem',
}



interface APIOrder {
  id: number
  orderNumber: string | null
  taxAmount: number
  taxableAmount: number
  totalAmount: number
  shippingAmount: number
  shippingTaxAmount: number
  internalNotes: string
  creationDate: Date | null,
  updateDate: Date | null,
  paymentDate: Date | null,
  paymentType: {
    name: string
    keyword: string
  }
  currency: {
    id: number
    name: string
    code: string
    htmlSymbol: string
  },
  address: {
    id: number
    firstname?: string
    lastname?: string
    address1?: string
    address2?: string
    city?: string
    zip?: string
    state?: string
    country?: string
    email?: string
    phone?: string
  } | null,
  billingAddress: {
    id: number
    firstname?: string
    lastname?: string
    address1?: string
    address2?: string
    city?: string
    zip?: string
    state?: string
    country?: string
    email?: string
    phone?: string
  } | null,
  billingInformation: {
    id: number
    businessName?: string
    companyName?: string
    fiscalCode?: string
    vatNumber?: string
    pec?: string
    sdi?: string
  }
  orderStatus: {
    id: number
    name: string
    keyword: string
  }
  orderItems: {
    id: number
    product: {
      id: number
      name: string
    }
    quantity: number
    currency: Currency
    totalAmount: number
    unitTaxAmount: number
    unitTaxableAmount: number
  }[],
  user: {
    id: number
    firstname: string
    lastname: string
    username: string
    email: string
    avatar: string
  } | null
}


export interface GetAllOrdersRequestParams extends APIPaginatable, APISortable, APISearchable, APIDateFilterable {
  status?: string
  fromPaymentDate?: string | null
  toPaymentDate?: string | null
  pharmacyId?: number
}

export interface GetAllOrdersResponse extends APIPaginatableResponse {
  orders: APIOrder[]
}

enum GetAllOrdersResponseError {
}

export async function getAllOrders(params: GetAllOrdersRequestParams, output?: RequestOutput<GetAllOrdersResponse, GetAllOrdersResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetAllOrders,
    params: params,
    output: output,
  })
}



export type GetOrderRequestParams = {
  id: number
  languageId: number
}
export type GetOrderResponse = {
  order: APIOrder
}

enum GetOrderResponseError {
}

export async function getOrder(params: GetOrderRequestParams, output?: RequestOutput<GetOrderResponse, GetOrderResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetOrderById,
    params: params,
    output: output,
  })
}


export type CreateOrderRequestParams = {
  taxableAmount?: number | null,
  taxAmount?: number | null,
  shippingAmount?: number | null,
  shippingTaxAmount?: number | null,
  totalAmount?: number | null,
  internalNotes?: string | null,
  orderStatusId?: number,
  addressId?: number | null,
  billingAddressId?: number | null,
  billingInformationId?: number | null,
  userId: number,
  currencyId: number,
  pharmacyId: number
}
export type CreateOrderResponse = {
  order: APIOrder
}

enum CreateOrderResponseError {
}

export async function createOrder(params: CreateOrderRequestParams, output?: RequestOutput<CreateOrderResponse, CreateOrderResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.CreateOrder,
    params: params,
    output: output,
  })
}



export type UpdateOrderRequestParams = {
  taxableAmount?: number,
  taxAmount?: number,
  shippingAmount?: number,
  shippingTaxAmount?: number,
  totalAmount?: number,
  internalNotes?: string,
  orderStatusId?: number,
  addressId?: number,
  billingAddressId?: number,
  billingInformationId?: number,
  userId?: number,
  currencyId?: number
}
export type UpdateOrderResponse = {
}

enum UpdateOrderResponseError {
}

export async function updateOrder(params: UpdateOrderRequestParams, output?: RequestOutput<UpdateOrderResponse, UpdateOrderResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateOrder,
    params: params,
    output: output,
  })
}




export type AddOrderItemRequestParams = {
  orderId?: number,
  quantity?: number,
  productId?: number,
  currencyId?: number
}
export type AddOrderItemResponse = {
}

enum AddOrderItemResponseError {
}

export async function addOrderItem(params: AddOrderItemRequestParams, output?: RequestOutput<AddOrderItemResponse, AddOrderItemResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.AddOrderItem,
    params: params,
    output: output,
  })
}




export type UpdateOrderItemRequestParams = {
  id?: number,
  quantity?: number,
}
export type UpdateOrderItemResponse = {
}

enum UpdateOrderItemResponseError {
}

export async function updateOrderItem(params: UpdateOrderItemRequestParams, output?: RequestOutput<UpdateOrderItemResponse, UpdateOrderItemResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateOrderItem,
    params: params,
    output: output,
  })
}