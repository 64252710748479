import { Button, Typography } from '@material-ui/core'
import { createPage, CreatePageRequestParams } from 'app/api'
import {
  getDashboardData, GetDashboardDataRequestParams, GetDashboardDataResponse,
} from 'app/api'
import { useLocalization } from 'components'
import { RootState } from 'app/session/store'
import { Pharmacy, DashboardData, Training } from 'app/entities/types'
import { Card, Grid, makeStyles } from '@material-ui/core'
import { toast, useRouter } from 'app/utils'
import { userRoleHasPermission } from 'app/entities/methods'
import { Page } from 'components'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CardListing, ListRequestParameters, ListHeaderProps, ListRowProps, ListSorting, CardField, StatusTag, StatusTagType } from 'components'
import { Color, FontWeight } from 'theme/style'
import clsx from 'clsx'
import { ModelValue, TrainingStatusValue, UserPermissionValue } from 'app/values'
import { updatePersistedListParameters } from 'app/session/actions'
import { userCan, userIs } from 'app/entities/methods'
import { UserRoleValue } from 'app/values'

const useStyles = makeStyles((theme) => ({
  dashboard_section: {
    textAlign: 'left',
    width: '100%',
    marginTop: theme.spacing(2),
    fontWeight: FontWeight.Bold
  },
  dashboard_card: {
    height: '200px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    cursor: 'pointer',
    backgroundColor: Color.White
  },
  dashboard_cardBlock: {
    cursor: 'default',
  },
  dashboard_name: {
    textAlign: 'center',
    color: Color.TextPrimary

  },
  dashboard_value: {
    textAlign: 'center',
    fontSize: '80px',
    fontWeight: FontWeight.UltraLight,
    color: Color.TextPrimary
  },
}))

const Dashboard = () => {
  const { t } = useLocalization()
  const router = useRouter()
  const classes = useStyles()
  const session = useSelector((state: RootState) => state.session)

  const [isLoading, setIsLoading] = useState(false)

  const [dashboardData, setDashboardData] = useState<DashboardData | null>(null)

  function fetchItems(request?: ListRequestParameters) {
    setIsLoading(true)

    let repEmail: string | undefined = undefined
    let dmEmail: string | undefined = undefined
    if (userIs(session.user, UserRoleValue.Rep) === true) repEmail = session.user.email
    if (userIs(session.user, UserRoleValue.DM) === true) dmEmail = session.user.email

    const encode = (): GetDashboardDataRequestParams => {
      return {
        userId: session.user.id,
        loggedRepEmail: repEmail,
        loggedDmEmail: dmEmail
      }
    }

    const decode = (data: GetDashboardDataResponse): DashboardData => {
      return data
    }

    getDashboardData(encode(), {
      response(data) {
        setDashboardData(decode(data))
      },
      error(error, message) {
        toast.error(message)
      }
    })
  }

  useEffect(() => {
    fetchItems()
  }, [])

  useEffect(() => {
    const initialized = dashboardData != null
    setIsLoading(!initialized)
  }, [dashboardData])



  //RENDER

  return (
    <Page title={'Dashboard'} isLoading={isLoading}>
      <Grid container spacing={3}>



        {/* FORMAZIONE */}

        {(
          userIs(session.user, UserRoleValue.Operator)
          || userIs(session.user, UserRoleValue.ZentivaMaster)
          || userIs(session.user, UserRoleValue.Rep)
          || userIs(session.user, UserRoleValue.DM)
          || userIs(session.user, UserRoleValue.APS)
          || userIs(session.user, UserRoleValue.WebsiteManager)
        ) === false &&
          <>
            <DashboardTitle title='Formazione' />

            <DashboardCard title={'Farmacie totali'} value={dashboardData?.pharmaciesCount}
              hideFor={[
              ]}
            />

            <DashboardCard title={'Farmacie arruolate'} value={dashboardData?.activatedCount}
              bg={'#D52E2E'} color={'#ffffff'}
              clinicaDigitale={true}
              hideFor={[
              ]}
            />

            <DashboardCard title={'Da formare'} value={dashboardData?.trainCount}
              trainingStatusParam={TrainingStatusValue.Appointed}
              bg={'#31b7f5'} color={'#ffffff'}
              hideFor={[
              ]}
            />

            <DashboardCard title={'Completate'} value={dashboardData?.completedCount}
              trainingStatusParam={TrainingStatusValue.Completed}
              bg={'#36C2A1'} color={'#ffffff'}
              hideFor={[
              ]}
            />

            <DashboardCard title={'Da contattare'} value={dashboardData?.contactCount}
              trainingStatusParam={TrainingStatusValue.Contact}
              bg={'#E175D4'} color={'#ffffff'}
              hideFor={[
              ]}
            />

            <DashboardCard title={'Richiesta di approfondimento'} value={dashboardData?.informCount}
              trainingStatusParam={TrainingStatusValue.Inform}
              bg={'#ff7a45'} color={'#ffffff'}
              hideFor={[
              ]}
            />

            <DashboardCard title={'Da ricontattare'} value={dashboardData?.rescheduleCount}
              trainingStatusParam={TrainingStatusValue.Reschedule}
              bg={'#b051de'} color={'#ffffff'}
              hideFor={[
              ]}
            />

            <DashboardCard title={'Annullate'} value={dashboardData?.declinedCount}
              trainingStatusParam={TrainingStatusValue.Declined}
              bg={'#E02B46'} color={'#ffffff'}
              hideFor={[
              ]}
            />
          </>
        }



        {/* MODELLI */}

        {/* {(
          userIs(session.user, UserRoleValue.CallCenter)
          || userIs(session.user, UserRoleValue.Operator)
          || userIs(session.user, UserRoleValue.APS)
          || userIs(session.user, UserRoleValue.WebsiteManager)
        ) === false &&
          <>
            <DashboardTitle title='Riepilogo modelli' />

            <DashboardCard title={'Farmacie arruolate'} value={dashboardData?.activatedCount}
              bg={'#D52E2E'} color={'#ffffff'}
              blockLink={true}
              hideFor={[
              ]}
            />

            <DashboardCard title={'Farmacie che richiedono call di approfondimento'} value={dashboardData?.informCount}
              bg={'#D6612F'} color={'#ffffff'}
              blockLink={true}
              hideFor={[
              ]}
            />

            <DashboardCard title={'Call di approfondimento prenotata'} value={dashboardData?.informBookedCount}
              bg={'#D59849'} color={'#ffffff'}
              blockLink={true}
              hideFor={[
              ]}
            />

            <DashboardCard title={'Call di approfondimento effettuata'} value={dashboardData?.informedCount}
              bg={'#D6B149'} color={'#ffffff'}
              blockLink={true}
              hideFor={[
              ]}
            />

            <DashboardSeparator />

            <DashboardCard title={'Farmacie interessate a Modello 1'} value={dashboardData?.model1Count}
              bg={'#3339E9'} color={'#ffffff'}
              model={ModelValue.Model1}
              hideFor={[
              ]}
            />

            <DashboardCard title={'Modello 1: Contattate'} value={dashboardData?.model1Contacted}
              bg={'#337BE8'} color={'#ffffff'}
              blockLink={true}
              hideFor={[
              ]}
            />

            {/* <DashboardCard title={'Modello 1: Consegna effettuata'} value={dashboardData?.model1Delivered}
              bg={'#33AFE8'} color={'#ffffff'}
              blockLink={true}
              hideFor={[
              ]}
            /> }

            <DashboardSeparator />

            <DashboardCard title={'Farmacie interessate a Modello 2'} value={dashboardData?.model2Count}
              bg={'#309F42'} color={'#ffffff'}
              model={ModelValue.Model2}
              hideFor={[
              ]}
            />

            <DashboardCard title={'Modello 2: Contattate'} value={dashboardData?.model2Contacted}
              bg={'#5EC76F'} color={'#ffffff'}
              blockLink={true}
              hideFor={[
              ]}
            />

            {/* <DashboardCard title={'Modello 2: Consegna effettuata'} value={dashboardData?.model2Delivered}
              bg={'#95EBA4'} color={'#ffffff'}
              blockLink={true}
              hideFor={[
              ]}
            /> }
          </>
        } */}



        {/* INTERESSI */}

        {/* {(
          userIs(session.user, UserRoleValue.CallCenter)
          || userIs(session.user, UserRoleValue.Operator)
          || userIs(session.user, UserRoleValue.ZentivaMaster)
          || userIs(session.user, UserRoleValue.Rep)
          || userIs(session.user, UserRoleValue.DM)
          || userIs(session.user, UserRoleValue.APS)
          || userIs(session.user, UserRoleValue.WebsiteManager)
        ) === false &&
          <>
            <DashboardTitle title='Interessi' />

            <DashboardCard title={'Farmacie con certificazione'} value={dashboardData?.certifiedCount}
              bg={'#b051de'} color={'#ffffff'}
              certification={true} />

            <DashboardSeparator />

            <DashboardCard title={'ECG da assegnare'} value={dashboardData?.toAssignECGCount}
              bg={'#D52E2E'} color={'#ffffff'}
              assigned={false} ECG={true} />

            <DashboardCard title={'Farmacie con ECG'} value={dashboardData?.ECGCount}
              bg={'#D6612F'} color={'#ffffff'}
              ECG={true} />

            <DashboardCard title={'Farmacie con kit ECG'} value={dashboardData?.kitECGCount}
              bg={'#D59849'} color={'#ffffff'}
              ECGKit={true} />

            <DashboardSeparator />

            <DashboardCard title={'Holter Cardiaco da assegnare'} value={dashboardData?.toAssignHCCount}
              bg={'#3339E9'} color={'#ffffff'}
              assigned={false} HC={true} />

            <DashboardCard title={'Farmacie con Holter Cardiaco'} value={dashboardData?.HCCount}
              bg={'#337BE8'} color={'#ffffff'}
              HC={true} />

            <DashboardCard title={'Farmacie con kit Holter Cardiaco'} value={dashboardData?.kitHCCount}
              bg={'#33AFE8'} color={'#ffffff'}
              HCKit={true} />

            <DashboardSeparator />

            <DashboardCard title={'Holter Pressorio da assegnare'} value={dashboardData?.toAssignHPCount}
              bg={'#309F42'} color={'#ffffff'}
              assigned={false} HP={true} />

            <DashboardCard title={'Farmacie con Holter Pressorio'} value={dashboardData?.HPCount}
              bg={'#5EC76F'} color={'#ffffff'}
              HP={true} />

            <DashboardCard title={'Farmacie con kit Holter Pressorio'} value={dashboardData?.kitHPCount}
              bg={'#95EBA4'} color={'#ffffff'}
              HPKit={true} />
          </>
        } */}

      </Grid>
    </Page >
  )
}

export default Dashboard



type DashboardCardProps = {
  title: string,
  value?: string | number
  color?: string,
  bg?: string,
  hideFor?: UserRoleValue[]
  trainingStatusParam?: TrainingStatusValue
  assigned?: boolean
  ECG?: boolean
  ECGKit?: boolean
  HC?: boolean
  HCKit?: boolean
  HP?: boolean
  HPKit?: boolean
  certification?: boolean
  clinicaDigitale?: boolean
  model?: ModelValue
  blockLink?: boolean
}

const DashboardCard = ({ ...props }: DashboardCardProps) => {
  const classes = useStyles()
  const router = useRouter()
  const dispatch = useDispatch()
  const session = useSelector((state: RootState) => state.session)

  function goToPharmacies() {
    if (props.blockLink === true) return
    if (userCan(session.user, UserPermissionValue.PharmaciesDisplay) !== true) return

    let opt: any = {}
    if (props.trainingStatusParam != null) {
      opt.trainingStatus = props.trainingStatusParam
    }
    if (props.assigned != null) {
      opt.assigned = props.assigned
    }
    if (props.ECG != null) {
      opt.ECGActive = props.ECG
    }
    if (props.ECGKit != null) {
      opt.ECGKitActive = props.ECGKit
    }
    if (props.HC != null) {
      opt.HCActive = props.HC
    }
    if (props.HCKit != null) {
      opt.HCKitActive = props.HCKit
    }
    if (props.HP != null) {
      opt.HPActive = props.HP
    }
    if (props.HPKit != null) {
      opt.HPKitActive = props.HPKit
    }
    if (props.certification != null) {
      opt.certificationActive = props.certification
    }
    if (props.clinicaDigitale != null) {
      opt.isClinicaDigitale = props.clinicaDigitale
    }
    if (props.model != null) {
      opt.model = props.model
    }

    dispatch(updatePersistedListParameters({
      listId: 'pharmacies',
      filters: opt
    }))

    router.history.push('/app/pharmacies')
  }

  let show: any = true
  props.hideFor?.forEach(r => {
    if (show === true && userIs(session.user, r) === true) show = false
  })
  if (show === false) return <></>

  return (
    <Grid item xs={12} sm={6} md={3}>
      <Card className={props.blockLink === true ? clsx(classes.dashboard_card, classes.dashboard_cardBlock) : clsx(classes.dashboard_card)} style={props.bg ? { backgroundColor: props.bg } : undefined} onClick={goToPharmacies}>
        <Typography className={classes.dashboard_value} style={props.color ? { color: props.color } : undefined} variant={'body1'}>{props.value ?? '–'}</Typography>
        <Typography className={classes.dashboard_name} style={props.color ? { color: props.color } : undefined} variant={'h3'}>{props.title}</Typography>
      </Card>
    </Grid>
  )
}

type DashboardTitleProps = {
  title: string,
}
const DashboardTitle = ({ ...props }: DashboardTitleProps) => {
  const classes = useStyles()
  const router = useRouter()
  const dispatch = useDispatch()
  const session = useSelector((state: RootState) => state.session)

  return (
    <Grid item xs={12}>
      <Typography className={classes.dashboard_section} variant={'h1'}>{props.title}</Typography>
    </Grid>
  )
}

type DashboardSeparatorProps = {
}
const DashboardSeparator = ({ ...props }: DashboardSeparatorProps) => {
  const classes = useStyles()
  const router = useRouter()
  const dispatch = useDispatch()
  const session = useSelector((state: RootState) => state.session)

  return (
    <Grid item xs={12} style={{ padding: 0 }}>
    </Grid>
  )
}