import React, { useState, useEffect } from 'react'
import { CardPageData, PageEntityDetailsRefreshableProps } from 'components'
import { Page, Language } from 'app/entities/types'

interface PageDataProps extends PageEntityDetailsRefreshableProps {
	object: Page
	language: Language
	pageId: number
	parents?: Page[] | null
}

const PageData = ({ ...props }: PageDataProps) => {

	return (
		<CardPageData object={props.object} pageId={props.pageId} language={props.language} refreshObject={props.refreshObject} parents={props.parents} />
	)
}

export default PageData