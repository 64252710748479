import typography from 'theme/config/typography';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'

const breakpoints = createBreakpoints({})

export default {
  root: {
    ...typography.h3,
    padding: '20px',
    [breakpoints.down('sm')]: {
      padding: '8px 20px',
    },
  },
};
