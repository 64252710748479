import executeRequest, { RequestOutput } from 'app/api'
import { APIPaginatable, APIPaginatableResponse, APISortable, APISearchable, APIDateFilterable } from 'app/api/types'
import { API_Training } from 'app/api/project/trainings'
import { API_Model } from 'app/api/project/models'
import { API_ZentivaContact } from 'app/api/project/zentivaContact'
import { API_DM } from 'app/api/project/DM'



//ENDPOINTS

enum APIEndpoint {
  GetAllPharmacyLevels = 'api/pharmacyLevels',
  UpdatePharmacyLevel = 'api/pharmacyLevels/update',
}



//API TYPES

export type API_PharmacyLevel = {
  id: number
  name?: string
  keyword?: string
}



//REQUESTS

export interface GetAllPharmacyLevelsRequestParams extends APIPaginatable, APISortable, APISearchable, APIDateFilterable {
}
export interface GetAllPharmacyLevelsResponse extends APIPaginatableResponse {
  pharmacyLevels: API_PharmacyLevel[]
}
enum GetAllPharmacyLevelsResponseError {
}
export async function getAllPharmacyLevels(params: GetAllPharmacyLevelsRequestParams, output?: RequestOutput<GetAllPharmacyLevelsResponse, GetAllPharmacyLevelsResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetAllPharmacyLevels,
    params: params,
    output: output,
  })
}




export interface UpdatePharmacyLevelRequestParams extends API_PharmacyLevel {
}
export type UpdatePharmacyLevelResponse = {
}
enum UpdatePharmacyLevelResponseError {
}
export async function updatePharmacyLevel(params: UpdatePharmacyLevelRequestParams, output?: RequestOutput<UpdatePharmacyLevelResponse, UpdatePharmacyLevelResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdatePharmacyLevel,
    params: params,
    output: output,
  })
}