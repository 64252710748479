import executeRequest, { RequestOutput } from 'app/api'
import { APIPaginatable, APIPaginatableResponse, APISortable, APISearchable } from 'app/api/types'



//ENDPOINTS

enum APIEndpoint {
  GetAllTrainingStatuses = 'api/trainingStatuses'
}



//API TYPES

export type API_TrainingStatus = {
  id: number
  name?: string
  keyword?: string
}



//REQUESTS

export interface GetAllTrainingStatusesRequestParams extends APIPaginatable, APISortable, APISearchable {
}
export interface GetAllTrainingStatusesResponse extends APIPaginatableResponse {
  trainingStatuses: API_TrainingStatus[]
}
enum GetAllTrainingStatusesResponseError {
}
export async function getAllTrainingStatuses(output?: RequestOutput<GetAllTrainingStatusesResponse, GetAllTrainingStatusesResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetAllTrainingStatuses,
    output: output,
  })
}
