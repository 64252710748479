import { Card, makeStyles } from '@material-ui/core';
import {
  addTaxonomyToPage, AddTaxonomyToPageRequestParams,
  removeTaxonomyFromPage, RemoveTaxonomyFromPageRequestParams
} from 'app/api';
import { useLocalization } from 'components';
import { TaxonomablePage, Taxonomy } from 'app/entities/types';
import { toast } from 'app/utils';
import clsx from 'clsx';
import { CardContent, CardHeader, CardField, PageEntityDetailsRefreshableProps } from 'components';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  taxonomiesContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  taxonomy: {
    margin: theme.spacing(0.5),
  },
}))

enum InputType {
  Taxonomy = 'fieldTaxonomy',
}

interface TaxonomiesProps extends PageEntityDetailsRefreshableProps {
  className?: string,
  object: TaxonomablePage,
  taxonomiesList: Taxonomy[] | null,
  isLoading: boolean
}

const CardTaxonomies = ({ ...props }: TaxonomiesProps) => {
  const classes = useStyles()
  const { t } = useLocalization()

  const [activeTaxonomies, setActiveTaxonomies] = useState<Taxonomy[]>([])

  useEffect(() => {
    if (props.object.taxonomies == null) return
    if (props.taxonomiesList == null) return

    let items: Taxonomy[] = []
    props.taxonomiesList.map(t => {
      if (props.object.taxonomies?.find(tax => t.id === tax.id) != null) {
        items.push(t)
      }
    })
    setActiveTaxonomies(items)
  }, [props.taxonomiesList])

  function updateFieldValue(name: string, value: any, action?: string, object?: Taxonomy) {
    switch (name) {
      case InputType.Taxonomy: {
        setActiveTaxonomies(value)
        updateTaxonomies(object, action)
        break
      }
    }
  }

  function updateTaxonomies(taxonomy?: Taxonomy, action?: string) {
    if (props.object == null) return
    if (taxonomy == null) return
    if (action == null) return

    const addTaxonomy = (taxonomy: Taxonomy) => {
      const params: AddTaxonomyToPageRequestParams = {
        pageId: props.object.pageId,
        taxonomyId: taxonomy.id
      }

      addTaxonomyToPage(params, {
        response(data) {
          toast.success('Categoria aggiunta')
          if (props.refreshObject != null) props.refreshObject()
        },
        error(error, message) {
          toast.error(message)
        }
      })
    }

    const removeTaxonomy = (taxonomy: Taxonomy) => {
      const params: RemoveTaxonomyFromPageRequestParams = {
        pageId: props.object.pageId,
        taxonomyId: taxonomy.id
      }

      removeTaxonomyFromPage(params, {
        response(data) {
          toast.success('Categoria rimossa')
          if (props.refreshObject != null) props.refreshObject()
        },
        error(error, message) {
          toast.error(message)
        }
      })
    }

    switch (action) {
      case 'add':
        addTaxonomy(taxonomy)
        break

      case 'remove':
        removeTaxonomy(taxonomy)
        break

      default:
        break
    }
  }

  return (
    <Card className={clsx(classes.root, props.className)}>
      <CardHeader title={'Categorie'} iconName="tags" isLoading={props.isLoading} />
      <CardContent isLoading={props.isLoading}>
        <CardField type={'tags'} name={InputType.Taxonomy}
          placeholder={t('common.search')}
          items={props.taxonomiesList ?? []} activeItems={activeTaxonomies}
          itemValueConstructor={(option) => option.translation[0].name ?? ''}
          onUpdate={updateFieldValue}
        />
      </CardContent>
    </Card>
  )
}

export default CardTaxonomies