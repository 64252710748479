import { Color, FontFamily, FontSize, FontWeight, Value } from 'theme/style'
import spacing from 'theme/config/spacing'

export default {
  root: {
    fontFamily: FontFamily.Subtitle,
    fontSize: FontSize.Caption,
    fontWeight: FontWeight.Bold,
    backgroundColor: Color.Background,
    color: Color.TextPrimary,
    borderRadius: Value.BorderRadius_Field,
    margin: spacing(0.5),
  },
};
