
//Defines all the DEFAULT User roles available in the app.
//New roles can always be added from the backoffice without them being mapped here.
//IMPORTANT: Must be mapped to each specific user role keyword to work properly.

export enum UserRoleValue {
  Dev = 'dev',
  Admin = 'administrator',
  WebsiteManager = 'website-manager',
  Operator = 'operator',
  CallCenter = 'call-center',
  Rep = 'rep',
  DM = 'dm',
  ZentivaMaster = 'zentiva-master',
  APS = 'aps',
}