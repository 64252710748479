import spacing from 'theme/config/spacing'

export default {
  toolbar: {
    paddingLeft: spacing(3),
    paddingRight: spacing(3),
    '& > div': {
      '& > div': {
        '&:nth-child(2)': {
          paddingRight: spacing(1),
          justifyContent: 'flex-end',
          alignItems: 'flex-end'
        }
      }
    }
  },
};
