import { makeStyles, Typography } from '@material-ui/core'
import _ from 'lodash';
import { Color } from 'theme/style';

const useStyles = makeStyles((theme) => ({
  card_separator: {
    width: '100%',
    height: '40px',
    margin: theme.spacing(0, 1),
    marginTop: theme.spacing(3),
  },
  card_separatorTitle: {
    color: Color.TextPrimary
  },
}))

interface CardSubtitleProps {
  title?: string
}

const CardSubtitle = ({ ...props }: CardSubtitleProps) => {
  const classes = useStyles()

  return <div className={classes.card_separator}>
    <Typography className={classes.card_separatorTitle} variant={'h3'}>{props.title}</Typography>
  </div>
}

export default CardSubtitle
