
//Defines all the Media Library Folders available in the app.
//IMPORTANT: Must be mapped to each specific media library folder keyword to work properly.
export enum MediaLibraryFolderValue {
  General = 'general',
  Article = 'articles',
  ArticleTaxonomy = 'articles-taxonomies',
  Product = 'products',
  ProductTaxonomy = 'products-taxonomies',
  Avatar = 'avatars',

  PharmacyLoanDocument = 'pharmacy-loan-documents'
}
