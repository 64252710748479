import React, { ReactNode } from 'react'
import { Button, DialogProps, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Typography, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: '700px',
    maxWidth: '95%'
  },
}))

interface ExportDialogProps extends DialogProps {
  description?: string
  confirmText?: string
  content?: ReactNode
  onConfirm?: () => void
  onClose?: () => void
  exportParameters?: { [key: string]: any }
  children?: any
}


const ExportDialog = ({ ...props }: ExportDialogProps) => {
  const classes = useStyles()

  function handleClose() {
    if (props.onClose != null) props.onClose()
  }

  const confirmDialog = () => {
    handleClose()
    if (props.onConfirm != null) props.onConfirm()
  }

  return (
    <>
      <Dialog open={props.open} onClose={props.onClose} classes={{ paper: classes.dialog }}>
        <DialogTitle>
          <Typography component="h3" variant="h3">{props.title}</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{props.description}</DialogContentText>
          {props.content}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="text">Annulla</Button>
          <Button onClick={confirmDialog}>{props.confirmText ?? 'Esporta'}</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ExportDialog
