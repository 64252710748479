import spacing from 'theme/config/spacing'
import { Value } from 'theme/style'

export default {
  root: {
    padding: spacing(2),
    borderRadius: Value.BorderRadius,
    height: '100%'
  },
};
