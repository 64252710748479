import React, { useState, useEffect } from 'react'
import _ from 'lodash';
import { makeStyles, Button, IconButton, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, } from '@material-ui/core'
import { DeleteButton, CardField, FieldsListErrors, CardSubtitle, CardHeader, CardContent, CardListing, PageEntityDetailsRefreshableProps, useLocalization, ListHeaderProps, ListRowProps, ListSorting, StatusTag, StatusTagType, BaseButton } from 'components'
import { Training, Appointment } from 'app/entities/types'
import { toast } from 'app/utils'
import {
	getAppointmentsByTraining, GetAppointmentsByTrainingRequestParams, GetAppointmentsByTrainingResponse,
	createAppointment, CreateAppointmentRequestParams, CreateAppointmentResponse,
	updateAppointment, UpdateAppointmentRequestParams, UpdateAppointmentResponse,
	deleteAppointment, DeleteAppointmentRequestParams
} from 'app/api'
import { processFieldsErrors } from 'components/_CardPartials/CardField/methods'
import { userIs, userCan } from 'app/entities/methods'
import { UserPermissionValue, UserRoleValue } from 'app/values'
import moment from 'moment';
import { parseDate } from 'app/utils/date'
import { config } from 'app/config'
import { RootState } from 'app/session/store';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
	dateCol: {
		flex: '0 0 200px'
	},
	resultCol: {
		flex: '0 0 200px'
	},
	actionsCol: {
		flex: '0 0 50px'
	},
	dialog: {
		width: '700px',
		maxWidth: '95%'
	},
}))

interface StatusProps extends PageEntityDetailsRefreshableProps {
	object?: Training,
}

const Status = ({ ...props }: StatusProps) => {
	const { t } = useLocalization()
	const classes = useStyles()
	const session = useSelector((state: RootState) => state.session)

	const [isLoading, setIsLoading] = useState(false)
	const [appointments, setAppointments] = useState<Appointment[] | null>(null)
	const [enableCreate, setEnableCreate] = useState<boolean>(false)

	useEffect(() => {
		const initialized = appointments != null
		setIsLoading(!initialized)
	}, [appointments])



	//APPOINTMENTS DATA

	const sorting: ListSorting = { order: 'id', direction: 'asc' }

	const headers: ListHeaderProps = [
		{ key: 'id', sortable: true },
		{ key: 'date', name: 'Data', className: classes.dateCol },
		{ key: 'result', name: 'Risultato', className: classes.resultCol },
		{ key: 'notes', name: 'Note' },
	]

	const createRow = (item: Training): ListRowProps => {
		return {
			item: item,
			data: [
				{ key: 'id', value: item.id },
				{ key: 'date', value: printDate(item), className: classes.dateCol },
				{ key: 'result', value: printResult(item), className: classes.resultCol },
				{ key: 'notes', value: printNotes(item) },
			],
			action: function (item: Appointment) {
				editAppointment(item)
			}
		}

		function printDate(item: Appointment): string {
			if (item.schedule == null) return ''
			const date = parseDate(item.schedule)
			return moment.utc(date).format(config.app.defaultDateTimeFormat)
		}
		function printResult(item: Appointment): React.ReactNode {
			let text = 'Da fissare'
			let type: StatusTagType = 'warning'

			if (item.result === true) { type = 'success'; text = 'Fissato' }
			if (item.result === false) { type = 'error'; text = 'Annullato' }

			return <StatusTag type={type} size="small" label={text} />
		}
		function printNotes(item: Appointment): string {
			return item.notes ?? '–'
		}
	}



	//APPOINTMENTS

	type DataProps = {
		schedule?: Date | null
		result?: boolean | null
		notes?: string | null
	}
	const [appointmentData, setAppointmentData] = useState<DataProps>({
		schedule: null,
		result: null,
		notes: null,
	})

	useEffect(() => {
		fetchAppointments()
	}, [])

	function fetchAppointments() {
		if (props.object == null) {
			setAppointments([])
			return
		}

		const encode = (): GetAppointmentsByTrainingRequestParams => {
			return {
				trainingId: props.object!.id
			}
		}
		const decode = (data: GetAppointmentsByTrainingResponse): Appointment[] => {
			let appointments = data.appointments
			return appointments
		}

		setIsLoading(true)
		getAppointmentsByTraining(encode(), {
			response(data) {
				const appointments = decode(data)
				setAppointments(appointments)
				setIsLoading(false)

				let count = 0
				for (let i = 0; i < appointments.length; i++) {
					const appointment = appointments[i]
					if (appointment.result === true || appointment.result === null) count++
				}

				let enableCreate = true
				if (enableCreate === true) enableCreate = userCan(session.user, UserPermissionValue.AppointmentsManagement) === true
				setEnableCreate(enableCreate)
			},
			error(error, message) {
				setIsLoading(false)
			}
		})
	}

	function createNewAppointment() {
		if (props.object == null) return

		const encode = (): CreateAppointmentRequestParams => {
			return {
				trainingId: props.object!.id
			}
		}
		const decode = (data: CreateAppointmentResponse): Appointment => {
			let appointment = data.appointment
			return appointment
		}

		createAppointment(encode(), {
			response(data) {
				const appointment = decode(data)
				fetchAppointments()
			},
			error(error, message) {
			}
		})
	}

	function deleteSelectedAppointment() {
		if (selectedAppointment == null) return

		const encode = (): DeleteAppointmentRequestParams => {
			return {
				id: selectedAppointment?.id
			}
		}
		deleteAppointment(encode(), {
			response(data) {
				fetchAppointments()
				closeAppointmentDialog()
			},
			error(error, message) {
			}
		})
	}

	function updateSelectedAppointment() {
		if (selectedAppointment == null) return

		const encode = (): UpdateAppointmentRequestParams => {
			return {
				id: selectedAppointment.id,
				schedule: moment(appointmentData.schedule).format(config.api.dateFormat),
				result: appointmentData.result,
				notes: appointmentData.notes ?? ''
			}
		}
		const decode = (data: UpdateAppointmentResponse): Appointment => {
			let appointment = data.appointment
			return appointment
		}

		updateAppointment(encode(), {
			response(data) {
				fetchAppointments()
				closeAppointmentDialog()
			},
			error(error, message) {
			}
		})
	}

	function editAppointment(appointment: Appointment) {
		setSelectedAppointment(appointment)
	}



	//DIALOG

	const [appointmentDialogOpen, setAppointmentDialogOpen] = React.useState(false)
	const [selectedAppointment, setSelectedAppointment] = React.useState<Appointment | null>(null)

	function closeAppointmentDialog() {
		setAppointmentDialogOpen(false)
		setSelectedAppointment(null)
	}

	useEffect(() => {
		if (selectedAppointment == null) return
		setAppointmentData({
			schedule: parseDate(selectedAppointment.schedule) ?? new Date(),
			result: selectedAppointment.result,
			notes: selectedAppointment.notes,
		})

		setAppointmentDialogOpen(true)
	}, [selectedAppointment])


	function updateFieldValue(name: string, value: any) {
		const temp = _.clone(appointmentData)

		switch (name) {
			case 'schedule': temp.schedule = value; break
			case 'result':
				if (value === 0) temp.result = null;
				if (value === 1) temp.result = true;
				if (value === 2) temp.result = false;
				break
			case 'notes': temp.notes = value; break
			default: break
		}

		setAppointmentData(temp)
	}

	function resultValue(value?: boolean | null): number {
		if (value === null) return 0;
		if (value === true) return 1;
		if (value === false) return 2;
		return 0
	}


	//DIALOG ERROR HANDLING

	enum DialogFieldName {
		Notes = 'notes',
	}

	const errorsConditions: FieldsListErrors = {
		[DialogFieldName.Notes]: [
			{
				error: appointmentData.result === false && appointmentData.notes?.length == 0,
				errorMessage: 'Le note sono obbligatorie',
			}
		],
	}

	const [errors, setErrors] = useState<FieldsListErrors>({})

	useEffect(() => {
		setErrors(processFieldsErrors(DialogFieldName, errorsConditions))
	}, [appointmentData])



	//RENDER

	if (userCan(session.user, UserPermissionValue.AppointmentsDisplay) === false) {
		return <></>
	}

	return (
		<>
			<CardListing
				enableSearch={false}
				enableFooter={false}
				actions={
					enableCreate === true && <Button onClick={createNewAppointment}>Crea nuovo</Button>
				}
				id={'appointments'}
				title={'Richiesta approfondimento servizi'}
				isLoading={isLoading}
				items={appointments}
				headers={headers}
				dataConstructor={createRow}
				sorting={sorting}
			/>

			<Dialog classes={{ paper: classes.dialog }} open={appointmentDialogOpen} onClose={closeAppointmentDialog}>
				<DialogTitle>
					<Typography component="h3" variant="h3">Modifica appuntamento</Typography>
				</DialogTitle>
				<DialogContent>
					<CardField type={'date'} name={'schedule'}
						label={'Data del task'}
						value={moment.utc(appointmentData.schedule) ?? ''}
						onUpdate={updateFieldValue}
					/>
					<CardField type={'select'} name={'result'}
						layout={'vertical'}
						label={'Stato appuntamento'}
						value={resultValue(appointmentData.result)}
						options={[
							{ key: '1', value: 0, name: 'Da fissare' },
							{ key: '2', value: 1, name: 'Fissato' },
							{ key: '3', value: 2, name: 'Annullato' }
						]}
						onUpdate={updateFieldValue}
					/>
					<CardField type={'textarea'} name={DialogFieldName.Notes}
						label={'Note aggiuntive'}
						value={appointmentData.notes ?? ''}
						onUpdate={updateFieldValue}
					/>
				</DialogContent>
				<DialogActions>
					{userCan(session.user, UserPermissionValue.AdminFeatures) === true && <DeleteButton onConfirm={deleteSelectedAppointment}>{'Elimina'}</DeleteButton>}
					<Button onClick={closeAppointmentDialog} variant="text" autoFocus>Annulla</Button>
					<BaseButton variant="contained" color={'primary'} onClick={updateSelectedAppointment}
						mandatoryFields={['notes']} fieldsConditions={errors}
					>Salva</BaseButton>
				</DialogActions>
			</Dialog>
		</>
	)
}

export default Status