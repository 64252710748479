import { colors } from '@material-ui/core';
import { Color } from 'theme/style';

export default {
  root: {
    color: Color.Primary,
    '&:hover': {
      backgroundColor: colors.grey[100]
    },
    '&$selected': {
      backgroundColor: colors.grey[50],
      color: Color.Primary,
      '&:hover': {
        backgroundColor: colors.grey[100]
      }
    },
    '&:first-child': {
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4
    },
    '&:last-child': {
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4
    }
  }
};
