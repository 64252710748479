import { Color, FontSize, FontWeight } from 'theme/style';

export default {
  h1: {
    fontFamily: 'Circular, sans-serif',
    color: Color.TextPrimary,
    fontWeight: FontWeight.Medium,
    fontSize: FontSize.Headline1,
    letterSpacing: -0.7,
    lineHeight: 1.5
  },
  h2: {
    fontFamily: 'Circular, sans-serif',
    color: Color.TextPrimary,
    fontWeight: FontWeight.Bold,
    fontSize: FontSize.Headline2,
    letterSpacing: -0.5,
    lineHeight: 1.5
  },
  h3: {
    fontFamily: 'Circular, sans-serif',
    color: Color.TextPrimary,
    fontWeight: FontWeight.Medium,
    fontSize: FontSize.Headline3,
    letterSpacing: -0.5,
    lineHeight: 1
  },
  h4: {
    fontFamily: 'Circular, sans-serif',
    color: Color.TextPrimary,
    fontWeight: FontWeight.Medium,
    fontSize: FontSize.Headline4,
    letterSpacing: -0.5,
    lineHeight: 1
  },
  h5: {
    fontFamily: 'Circular, sans-serif',
    color: Color.TextPrimary,
    fontWeight: FontWeight.Medium,
    fontSize: FontSize.Headline5,
    letterSpacing: 0,
    lineHeight: 1.5
  },
  h6: {
    fontFamily: 'Circular, sans-serif',
    color: Color.TextPrimary,
    fontWeight: FontWeight.Medium,
    fontSize: FontSize.Headline6,
    letterSpacing: 0,
    lineHeight: 1.5
  },
  subtitle1: {
    fontFamily: 'Circular, sans-serif',
    color: Color.TextPrimary,
    fontWeight: FontWeight.Medium,
    fontSize: FontSize.Subtitle1,
    letterSpacing: 0,
    lineHeight: 1.3
  },
  subtitle2: {
    fontFamily: 'Circular, sans-serif',
    color: Color.TextSecondary,
    fontWeight: FontWeight.Medium,
    fontSize: FontSize.Subtitle2,
    letterSpacing: 0,
    lineHeight: 1.5
  },
  body1: {
    fontFamily: 'Circular, sans-serif',
    color: Color.TextPrimary,
    fontWeight: FontWeight.Regular,
    fontSize: FontSize.Body1,
    letterSpacing: -0.2,
    lineHeight: 1.3
  },
  body2: {
    fontFamily: 'Circular, sans-serif',
    color: Color.TextSecondary,
    fontSize: FontSize.Body2,
    fontWeight: FontWeight.Regular,
    letterSpacing: 0,
    lineHeight: 1.3
  },
  cell: {
    fontFamily: 'Circular, sans-serif',
    color: Color.TextPrimary,
    fontSize: FontSize.Cell,
    fontWeight: FontWeight.Regular,
    letterSpacing: 0,
    lineHeight: 1.5
  },
  button: {
    fontFamily: 'Circular, sans-serif',
    color: Color.TextPrimary,
    fontSize: FontSize.Button,
    fontWeight: FontWeight.SemiBold,
    letterSpacing: 0,
    lineHeight: 2,
  },
  caption: {
    fontFamily: 'Circular, sans-serif',
    color: Color.TextSecondary,
    fontSize: FontSize.Caption,
    fontWeight: FontWeight.Regular,
    letterSpacing: 0.3,
    lineHeight: 1.3
  },
  overline: {
    fontFamily: 'Circular, sans-serif',
    color: Color.TextPrimary,
    fontSize: FontSize.Overline,
    fontWeight: FontWeight.Bold,
    letterSpacing: 0.4,
    lineHeight: 1,
    textTransform: 'uppercase'
  }
};
