import executeRequest, { RequestOutput } from 'app/api'


enum APIEndpoint {
  GetDashboardData = 'api/dashboard',
}



//Retrieve the authentication token.
//Used when logging in and authorizing the client to make future API calls.

export type GetDashboardDataRequestParams = {
  userId: number
  loggedRepEmail?: string
  loggedDmEmail?: string
}

export type GetDashboardDataResponse = {
  ECGCount?: number
  HCCount?: number
  HPCount?: number
  activatedCount?: number
  certifiedCount?: number
  completedCount?: number
  contactCount?: number
  declinedCount?: number
  informBookedCount?: number
  informCount?: number
  informedCount?: number
  kitECGCount?: number
  kitHCCount?: number
  kitHPCount?: number
  model1Contacted?: number
  model1Count?: number
  model1Delivered?: number
  model2Contacted?: number
  model2Count?: number
  model2Delivered?: number
  pharmaciesCount?: number
  rescheduleCount?: number
  toAssignECGCount?: number
  toAssignHCCount?: number
  toAssignHPCount?: number
  trainCount?: number
}

enum GetDashboardDataReponseError {
}

export async function getDashboardData(params: GetDashboardDataRequestParams, output?: RequestOutput<GetDashboardDataResponse, GetDashboardDataReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetDashboardData,
    params: params,
    output: output,
  })
}
