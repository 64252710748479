import { makeStyles, Grid, GridProps } from '@material-ui/core'
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: '0 !important',
  },
}))

interface CardGridProps extends GridProps {
}

const CardGrid = ({ ...props }: CardGridProps) => {
  const classes = useStyles()

  return <Grid className={classes.root} {...props}>{props.children}</Grid>
}

export default CardGrid
