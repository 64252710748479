import { Grid } from '@material-ui/core';
import { Language, Page } from 'app/entities/types';
import { CardMetatags, PageEntityDetailsRefreshableProps } from 'components';
import React from 'react';

interface PageEntityTabSEOProps extends PageEntityDetailsRefreshableProps {
  object: Page
  language: Language
  pageId: number
}
const PageEntityTabSEO = ({ ...props }: PageEntityTabSEOProps) => {
  return (
    <>
      <Grid item xl={6} md={12}>
        <CardMetatags pageId={props.pageId} language={props.language} refreshObject={props.refreshObject} />
      </Grid>
    </>
  )
}

export default PageEntityTabSEO
