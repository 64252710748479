import { makeStyles } from '@material-ui/core'
import { Value } from 'theme/style'

const useStyles = makeStyles((theme) => ({
  root: {
    lineHeight: '0px',
    '&[disabled]': {
      opacity: 0.2,
    },
  },
  margin: {
    margin: theme.spacing(0, 1),
  },
  leftMargin: {
    marginLeft: theme.spacing(1),
  },
  rightMargin: {
    marginRight: theme.spacing(1),
  },
  image: {
    width: '20px',
    height: '20px',
    overflow: 'hidden',
    borderRadius: Value.BorderRadius
  }
}))


type LangugeFlagProps = {
  className?: string
  languageCode: string
  rest?: any
}
const LanguageFlag = ({ className, languageCode, ...rest }: LangugeFlagProps) => {
  const classes = useStyles()
  if (languageCode.length === 0) return <></>

  return (
    <div className={className} {...rest}>
      <img className={classes.image} src={'/images/flags/' + languageCode + '.svg'} alt={languageCode} />
    </div>
  )
}

export default LanguageFlag
