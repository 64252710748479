import React, { useEffect } from 'react'
import { Input, InputBaseProps, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { Color, FontWeight, Value } from 'theme/style'
import { config } from 'app/config'

const useStyles = makeStyles((theme) => ({
  inputContainer: {
    display: 'flex',
  },
  input: {
    flex: 1,
    backgroundColor: Color.Background,
    borderRadius: Value.BorderRadius_Field,
    padding: theme.spacing(0, 1.5),
    '& input, & textarea': {
      padding: theme.spacing(0),
      textAlign: 'left',
      height: '45px',
    },
    '& textarea': {
      padding: theme.spacing(1, 0),
      height: '100px !important',
    },
  },
  suffixText: {
    color: Color.Primary,
    fontWeight: FontWeight.Medium,
    letterSpacing: -0.5,
    fontSize: '1.2rem',
    marginLeft: theme.spacing(1)
  },
  prefixText: {
    color: Color.Primary,
    fontWeight: FontWeight.Medium,
    letterSpacing: -0.5,
    fontSize: '1.2rem',
    marginRight: theme.spacing(1)
  },
}))

interface CardInputFieldProps extends InputBaseProps {
  onUpdate?: (name: string, value: any) => void
  onConfirm?: (name: string, value: any) => void
}
const TextField = ({ ...props }: CardInputFieldProps) => {
  const classes = useStyles()



  //FIELD CHANGES

  function updateField(event: any) {
    const target = event.target as HTMLInputElement
    const name = target.name as string
    const value = target.value

    if (props.onUpdate == null) return
    props.onUpdate(name, value)
  }



  //RENDER

  return (
    <div className={classes.inputContainer}>
      <Input {...props} className={classes.input} onChange={updateField}></Input>
    </div>
  )
}

export default TextField



type TextFieldAdornmentProps = {
  text: string
}
export const TextFieldAdornmentSuffix = ({ text }: TextFieldAdornmentProps) => {
  const classes = useStyles()
  return (
    <Typography className={classes.suffixText}>{text}</Typography>
  )
}

export const TextFieldAdornmentPrefix = ({ text }: TextFieldAdornmentProps) => {
  const classes = useStyles()
  return (
    <Typography className={classes.prefixText}>{text}</Typography>
  )
}