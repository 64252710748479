import clsx from 'clsx'
import { makeStyles, Chip, ChipProps } from '@material-ui/core'
import { Color } from 'theme/style'

const useStyles = makeStyles((theme) => ({
  root: {
    transition: 'none'
  },
  tagSuccess: {
    backgroundColor: Color.TagSuccessBackground,
    color: Color.TagSuccessText
  },
  tagAlert: {
    backgroundColor: Color.TagAlertBackground,
    color: Color.TagAlertText
  },
  tagWarning: {
    backgroundColor: Color.TagWarningBackground,
    color: Color.TagWarningText
  },
  tagError: {
    backgroundColor: Color.TagErrorBackground,
    color: Color.TagErrorText
  },
  tagInfo: {
    backgroundColor: Color.TagInfoBackground,
    color: Color.TagInfoText
  },
  tagAlt: {
    backgroundColor: '#b051de',
    color: Color.White
  },
  tagAlt2: {
    backgroundColor: '#ff7a45',
    color: Color.White
  }
}))

export type StatusTagType = 'success' | 'error' | 'warning' | 'alert' | 'default' | 'info' | 'alt' | 'alt2'

interface StatusTagProps extends ChipProps {
  type?: StatusTagType
}

const StatusTag = ({ ...props }: StatusTagProps) => {
  const classes = useStyles()

  const typeValue = props.type ?? 'default'
  let tagClass = ''
  if (typeValue === 'success') tagClass = classes.tagSuccess
  if (typeValue === 'error') tagClass = classes.tagError
  if (typeValue === 'warning') tagClass = classes.tagWarning
  if (typeValue === 'alert') tagClass = classes.tagAlert
  if (typeValue === 'info') tagClass = classes.tagInfo
  if (typeValue === 'alt') tagClass = classes.tagAlt
  if (typeValue === 'alt2') tagClass = classes.tagAlt2

  return (
    <Chip {...props} className={clsx(classes.root, tagClass)} />
  )
}

export default StatusTag
