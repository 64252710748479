import executeRequest, { RequestOutput, APICurrency } from 'app/api'
import { APIPaginatable, APIPaginatableResponse, APISortable, APISearchable } from 'app/api/types'


//Endpoints

enum APIEndpoint {
  GetAllProducts = 'api/products',
}



//Types

export type APIProduct = {
  id: number
  keyword: string
  published: boolean | null
  sku: string
  featured?: boolean | null
  featuredOrder?: number
  pageId: number
  parentId: number | null
  tokenized: boolean
  onEnquiry: boolean | null
  year: number
  translation: APIProductTranslation[]
  prices: APIProductPrice[]
  dimensions: APIProductDimension,
  stock: APIProductStock,
  mainImage: APIProductImage,
  taxonomies: {
    id: number
    name: string
    slug: string
  }[]
}

export type APIProductTranslation = {
  name: string
  description?: string
  id: number,
  slug: string,
  ingredients: string,
  benefits: string,
  deepening: string,
  languageId: number
}
export type APIProductImage = {
  id: number
  type: string
  folder: number
  filename: string
  basepath: string
  path: string
}
export type APIProductTaxonomy = {
  id: number
  keyword: string
  pageId: number
  pageTypeId: number
  parentId: number
  published: boolean
  translation: {
    name: string
  }[]
}
export type APIProductStock = {
  outOfStock: boolean
  quantity: number
  maximalQuantity: number
  minimalQuantity: number
}
export type APIProductDimension = {
  width: number
  height: number
  depth: number
  weight: number
}
export type APIProductPrice = {
  id: number
  price: number
  sellingPrice: number
  wholesalePrice: number
  unitPrice: number
  unityCommonStringId: number
  currency: APICurrency
}



//Requests

export interface GetAllProductsRequestParams extends APIPaginatable, APISortable, APISearchable {
  languageId: number
}
export interface GetAllProductsResponse extends APIPaginatableResponse {
  products: APIProduct[]
}
enum GetAllProductsResponseError {
}
export async function getAllProducts(params: GetAllProductsRequestParams, output?: RequestOutput<GetAllProductsResponse, GetAllProductsResponseError>) {
  executeRequest({ endpoint: APIEndpoint.GetAllProducts, params: params, output: output })
}