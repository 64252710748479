import React, { Suspense, useState } from 'react'
import { makeStyles, LinearProgress, Hidden } from '@material-ui/core'
import { renderRoutes } from 'react-router-config'
import { Setup, Sidebar } from './components'
import { AppStatusBar } from 'components'
import { resetPersistedListParameters } from 'app/session/actions'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  wrapper: {
    flex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden'
  },
  appStatusBar: {
    flex: '0 0 auto',
    zIndex: 99999,
  },
  mainContainer: {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    overflow: 'hidden',
    zIndex: 1,
  },
  topBar: {
    position: 'relative',
  },
  navBar: {
    zIndex: 2,
    width: 100,
    minWidth: 100,
    overflow: 'hidden',
    flex: '0 0 auto',
    [theme.breakpoints.down('sm')]: {
      width: '60vw',
    },
  },
  content: {
    overflowY: 'auto',
    flex: '1 1 auto',
    backgroundColor: theme.palette.background.default
  },
  loader: {
    position: 'fixed',
    top: 0,
    width: '100%',
    left: 0,
    right: 0,
    zIndex: theme.zIndex.tooltip,
  },

  hamburgerMenu: {
    width: '60px',
    height: '60px',
    position: 'fixed',
    top: 0,
    left: 0,
    backgroundColor: theme.palette.primary.main,
    zIndex: 10,
  }
}))

type MainProps = {
  route: any
}
const Main = ({ route }: MainProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [sidebarIsOpen, setSidebarIsOpen] = useState(false)
  function toggleSidebar() {
    setSidebarIsOpen(!sidebarIsOpen)
  }

  function resetPersistedData() {
    dispatch(resetPersistedListParameters())
  }

  return (
    <Setup>
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <Hidden mdUp>
            <div className={classes.hamburgerMenu} onClick={toggleSidebar}>
              {sidebarIsOpen === true ? <img src={'/images/common/nav-close.svg'} />
                : <img src={'/images/common/nav-hamburger.svg'} />
              }
            </div>
          </Hidden>
          <Sidebar className={classes.navBar} openMobile={sidebarIsOpen} onClick={toggleSidebar} onItemClick={resetPersistedData} />
          <div className={classes.mainContainer}>
            <main className={classes.content}>
              <Suspense fallback={<LinearProgress className={classes.loader} />}>
                {renderRoutes(route.routes)}
              </Suspense>
            </main>
          </div>
        </div>
        <AppStatusBar className={classes.appStatusBar} />
      </div>
    </Setup >
  )
}


export default Main
