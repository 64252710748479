import CodeIcon from '@material-ui/icons/Code';

export default [
  {
    title: 'sidebar.title.dev', pages: [
      {
        title: 'sidebar.item.pages-dev', icon: CodeIcon, children: [
          { title: 'sidebar.item.pages-dev.regions', href: '/dev/pages/regions', },
          { title: 'sidebar.item.pages-dev.provinces', href: '/dev/pages/provinces', },
          { title: 'sidebar.item.pages-dev.cities', href: '/dev/pages/cities', },
        ]
      },
      {
        title: 'sidebar.item.mediaLibrary-dev', icon: CodeIcon, children: [
          { title: 'sidebar.item.mediaLibrary-dev.folders', href: '/dev/media-library/folders', },
        ]
      },
      {
        title: 'sidebar.item.types-dev', icon: CodeIcon, children: [
          { title: 'sidebar.item.types-dev.page', href: '/dev/types/page', },
          { title: 'sidebar.item.types-dev.fieldGroup', href: '/dev/types/field-group', },
          { title: 'sidebar.item.types-dev.field', href: '/dev/types/field', },
        ]
      },
      {
        title: 'sidebar.item.users-dev', icon: CodeIcon, children: [
          { title: 'sidebar.item.users-dev.roles', href: '/dev/users/roles', },
          { title: 'sidebar.item.users-dev.permissions', href: '/dev/users/permissions', },
        ]
      },
      {
        title: 'sidebar.item.tags-dev', icon: CodeIcon,
        href: '/dev/tags',
      },
    ]
  },
]