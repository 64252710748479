import { renderRoutes } from 'react-router-config'

type DefaultProps = {
  route: any
}

const Default = ({ ...props }: DefaultProps) => {
  return (
    <>
      {renderRoutes(props.route.routes)}
    </>
  )
}


export default Default
