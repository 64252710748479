import executeRequest, { RequestOutput } from 'app/api'
import { APIPaginatable, APIPaginatableResponse, APISortable, APISearchable } from 'app/api/types'



//ENDPOINTS

enum APIEndpoint {
  GetAllAppointments = 'api/appointments',
  GetAppointmentsByTraining = 'api/appointments/getByTraining',
  GetAppointmentById = 'api/appointments/getById',
  CreateAppointment = 'api/appointments/create',
  UpdateAppointment = 'api/appointments/update',
  DeleteAppointment = 'api/appointments/delete',
}



//API TYPES

export type API_Appointment = {
  id: number
  schedule?: Date
  result?: boolean
  notes?: string
}



//REQUESTS

export interface GetAllAppointmentsRequestParams extends APIPaginatable, APISortable, APISearchable {
}
export interface GetAllAppointmentsResponse extends APIPaginatableResponse {
  appointments: API_Appointment[]
}
enum GetAllAppointmentsResponseError {
}
export async function getAllAppointments(params: GetAllAppointmentsRequestParams, output?: RequestOutput<GetAllAppointmentsResponse, GetAllAppointmentsResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetAllAppointments,
    params: params,
    output: output,
  })
}



export interface GetAppointmentsByTrainingRequestParams extends APIPaginatable, APISortable, APISearchable {
  trainingId: number
}
export interface GetAppointmentsByTrainingResponse extends APIPaginatableResponse {
  appointments: API_Appointment[]
}
enum GetAppointmentsByTrainingResponseError {
}
export async function getAppointmentsByTraining(params: GetAppointmentsByTrainingRequestParams, output?: RequestOutput<GetAppointmentsByTrainingResponse, GetAppointmentsByTrainingResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetAppointmentsByTraining,
    params: params,
    output: output,
  })
}




export type GetAppointmentByIdRequestParams = {
  id: number
}
export type GetAppointmentByIdResponse = {
  appointment: API_Appointment
}
enum GetAppointmentByIdResponseError {
}
export async function getAppointmentById(params: GetAppointmentByIdRequestParams, output?: RequestOutput<GetAppointmentByIdResponse, GetAppointmentByIdResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetAppointmentById,
    params: params,
    output: output,
  })
}



export type CreateAppointmentRequestParams = {
  trainingId: number
}
export type CreateAppointmentResponse = {
  appointment: API_Appointment
}
enum CreateAppointmentResponseError {
}
export async function createAppointment(params: CreateAppointmentRequestParams, output?: RequestOutput<CreateAppointmentResponse, CreateAppointmentResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.CreateAppointment,
    params: params,
    output: output,
  })
}


export type UpdateAppointmentRequestParams = {
  id: number
  schedule?: string
  result?: boolean | null
  notes?: string
}
export type UpdateAppointmentResponse = {
  appointment: API_Appointment
}
enum UpdateAppointmentResponseError {
}
export async function updateAppointment(params: UpdateAppointmentRequestParams, output?: RequestOutput<UpdateAppointmentResponse, UpdateAppointmentResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateAppointment,
    params: params,
    output: output,
  })
}



export type DeleteAppointmentRequestParams = {
  id: number
}
export type DeleteAppointmentResponse = {
}
enum DeleteAppointmentResponseError {
}
export async function deleteAppointment(params: DeleteAppointmentRequestParams, output?: RequestOutput<DeleteAppointmentResponse, DeleteAppointmentResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.DeleteAppointment,
    params: params,
    output: output,
  })
}