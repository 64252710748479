import React, { ReactNode, useEffect, useRef } from 'react'
import clsx from 'clsx'
import { InputBaseProps, MenuItem, Typography, Link } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning';
import { Tooltip, Spinner, TextField, TextFieldAdornmentPrefix, TextFieldAdornmentSuffix, BooleanField, SelectField, RichTextField, RichTextFieldToolbarType, ButtonField, ImageField, TagField, DateField } from 'components'
import { makeStyles } from '@material-ui/core'
import { Color, FontSize, FontWeight, Value } from 'theme/style'
import spacing from 'theme/config/spacing';
import _ from 'lodash';
import { config } from 'app/config'
import DateRangeIcon from '@material-ui/icons/DateRange';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginBottom: spacing(1.5),
    },
  },
  infoContainer: {
    position: 'relative',
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  infoContainerVertical: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& >p': {
      marginBottom: theme.spacing(1),
    }
  },
  infoContainerHorizontal: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  labelContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: theme.spacing(1)
  },
  label: {
    fontWeight: FontWeight.Medium,
    fontSize: FontSize.Body1,
    color: Color.TextPrimary,
    textAlign: 'left'
  },
  link: {
    fontWeight: FontWeight.Regular,
    fontSize: FontSize.Body1,
    color: Color.Secondary,
    textAlign: 'right'
  },
  descriptionContainer: {
  },
  description: {
    marginTop: spacing(1),
    color: Color.TextSecondary,
    fontSize: FontSize.Body2,
  },
  fieldContainer: {
    alignItems: 'flex-end',
    display: 'flex',
    justifyContent: 'flex-end',
    minHeight: '44px',
    height: 'auto',
    width: '100%',
    flex: '0 0 1',
    position: 'relative',
    borderRadius: Value.BorderRadius_Field,
    overflow: 'hidden',
    '& >div': {
      height: '100%',
      width: '100%',
      justifyContent: 'flex-start',
    }
  },
  fieldContainerSmall: {
    minHeight: '36px',
    height: '36px',
    borderRadius: Value.BorderRadius_Field,
  },
  fieldContainerLeftAligned: {
    justifyContent: 'flex-start',
  },

  startIconContainer: {
    color: Color.TextPrimary,
    width: '45px !important',
    height: '45px',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center !important',
    left: 0,
    top: 0,
    zIndex: 999,
    '& > svg': {
      height: '20px',
      width: '20px',
      color: Color.TextSecondary
    }
  },
  endIconContainer: {
    color: Color.TextPrimary,
    width: '45px !important',
    height: '45px',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center !important',
    right: 0,
    top: 0,
    zIndex: 999,
  },

  disabledIcon: {
    pointerEvents: 'none'
  },
  errorIcon: {
    color: Color.Warning,
    width: '2.2rem !important',
    height: '2.2rem',
    zIndex: 999
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    height: '100%',
    top: 0,
    left: 0,
    width: '100%',
  },

  inputTextFieldLeftPadding: {
    '& input, & textarea': {
      paddingLeft: '30px !important'
    }
  },
  inputTextFieldRightPadding: {
    '& input, & textarea': {
      paddingRight: '45px !important'
    }
  },

  richTextBig: {
    maxHeight: '70vh'
  }
}))

export type FieldError = {
  error: boolean
  errorMessage: string
}

export type FieldsListErrors = { [key: string]: FieldError[] }


interface CardFieldProps extends InputBaseProps {
  className?: string
  isLoading?: boolean
  layout?: 'vertical' | 'horizontal'
  disabled?: boolean
  size?: 'small' | 'default'

  //The type of each field supported.
  type?: 'text' | 'textarea' | 'password' | 'email' | 'number' | 'select' | 'boolean' | 'richtext' | 'button' | 'image' | 'tags' | 'date'

  //Defines if the field should autosave when changed.
  autosave?: boolean

  //Common props.
  name: string
  label?: string | ReactNode
  placeholder?: string
  value?: any
  description?: string
  errors?: FieldsListErrors

  //Common methods.
  //Triggered when the field changed value.
  onUpdate?: (name: string, value: any, action?: string, object?: any) => void

  //Triggered when the field confirmed the change (or when autosaved).
  onConfirm?: (name: string, value: any) => void

  //Triggered when the field has been clicked (used on specific types such as Button and Image).
  onClick?: () => void

  //Triggered when the field has been removed its value (used on specific types such as Button and Image).
  onRemove?: () => void

  //Defines an icon placed at the end of the field.
  startIcon?: ReactNode
  endIcon?: ReactNode

  //Defines a prefix and a suffix (used in almost every field type).
  prefix?: string
  suffix?: string

  //Type Text specific props
  enableEnterConfirm?: boolean

  //Type Select specific props
  options?: { key: string, value: number | string | any, name: string }[]
  enableClear?: boolean

  //Type Date specific props
  minDate?: Date
  maxDate?: Date
  dateType?: 'date' | 'time' | 'datetime'

  //Type Tags specific props
  items?: any[]
  activeItems?: any[]
  itemValueConstructor?: (item: any) => string
  itemRenderClass?: (item: any) => string

  //Type RichText specific props
  richTextToolbarType?: RichTextFieldToolbarType

  enableEdit?: boolean
  actions?: React.ReactNode

  //Link props
  link?: {
    text: string
    url: string
  }
}

const CardField = ({ ...props }: CardFieldProps) => {
  const classes = useStyles()



  //DEFAULTS

  let layoutClass = clsx(classes.infoContainer, classes.infoContainerVertical)
  if (props.layout === 'horizontal') layoutClass = clsx(classes.infoContainer, classes.infoContainerHorizontal)
  if (props.layout === 'vertical') layoutClass = clsx(classes.infoContainer, classes.infoContainerVertical)



  //AUTOSAVE

  const autosaveDefaultValue = true
  const autosaveValue = props.autosave ?? autosaveDefaultValue

  //Automatically triggers the search confirm after a delay while typing.
  var timer: NodeJS.Timeout
  const data: any = useRef(null)
  data.current = props.onConfirm

  useEffect(() => {
    if (autosaveValue === false) return
    if (props.onConfirm == null) return
    if (props.name == null) return

    timer = setTimeout(() => {
      if (timer) clearTimeout(timer)
      data.current(props.name, props.value)
    }, config.automation.autosave.delay)
    return () => clearTimeout(timer)

  }, [props.value])



  //FIELD UPDATES

  function updateField(name: string, value: any, action?: string, object?: any) {
    if (props.onUpdate == null) return
    props.onUpdate(name, value, action, object)
  }

  function confirmField(name: string, value: any) {
    if (props.onConfirm == null) return
    props.onConfirm(name, value)
  }



  //RENDER

  function renderField(): ReactNode {
    if (props.isLoading != null && props.isLoading === true) return <div className={classes.loading}><Spinner type={'small'} /></div>
    return render()

    function render(): ReactNode {
      if (props.type == null) return <></>

      //Defines all common props passed to the different field types.
      let allProps: any = {
        name: props.name,
        placeholder: props.placeholder,
        value: props.value,
        disabled: props.disabled,
        onUpdate: updateField,
        onConfirm: confirmField,
      }
      if (props.prefix != null) {
        const adornment = <TextFieldAdornmentPrefix text={props.prefix} />
        allProps['startAdornment'] = adornment
      }
      if (props.suffix != null) {
        const adornment = <TextFieldAdornmentSuffix text={props.suffix} />
        allProps['endAdornment'] = adornment
      }

      let fieldStartIconClass = ''
      if (props.startIcon != null || props.type === 'date') {
        fieldStartIconClass = classes.inputTextFieldLeftPadding
      }

      let fieldEndIconClass = ''
      if (props.endIcon != null) {
        fieldEndIconClass = classes.inputTextFieldRightPadding
      }

      //Renders the "Text" type.
      if (props.type === 'text') return <TextField {...allProps} className={clsx(fieldStartIconClass, fieldEndIconClass)} enableEnterConfirm={props.enableEnterConfirm} />

      //Renders the "Textarea" type.
      if (props.type === 'textarea') return <TextField {...allProps} className={clsx(fieldStartIconClass, fieldEndIconClass)} multiline />

      //Renders the "Password" type.
      if (props.type === 'password') return <TextField {...allProps} type={'password'} className={clsx(fieldStartIconClass, fieldEndIconClass)} />

      //Renders the "Number" type.
      if (props.type === 'number') return <TextField {...allProps} type={'number'} className={clsx(fieldStartIconClass, fieldEndIconClass)} />

      //Renders the "Boolean" type.
      if (props.type === 'boolean') return <BooleanField {...allProps} />

      //Renders the "Rich Text" type.
      if (props.type === 'richtext') return <RichTextField {...allProps} toolbarType={props.richTextToolbarType} />

      //Renders the "Button" type.
      if (props.type === 'button') return <ButtonField {...allProps} toolbarType={props.richTextToolbarType} enableEdit={props.enableEdit} actions={props.actions} onClick={props.onClick} onRemove={props.onRemove} />

      //Renders the "Image" type.
      if (props.type === 'image') return <ImageField {...allProps} onClick={props.onClick} onRemove={props.onRemove} />

      //Renders the "Tags" type.
      if (props.type === 'tags') return <TagField {...allProps} items={props.items ?? []} activeItems={props.activeItems ?? []} itemValueConstructor={props.itemValueConstructor} itemRenderClass={props.itemRenderClass} />

      //Renders the "Date" type.
      if (props.type === 'date') return <DateField {...allProps} className={clsx(fieldStartIconClass, fieldEndIconClass)} minDate={props.minDate} maxDate={props.maxDate} type={props.dateType} />

      //Renders the "Select" type.
      if (props.type === 'select') return <SelectField {...allProps} enableClear={props.enableClear}>
        {props.options?.map((o) => (
          <MenuItem key={o.key} value={o.value}>{o.name}</MenuItem>
        ))}
      </SelectField>
    }
  }

  function renderIcon(type: 'start' | 'end'): ReactNode {
    if (props.errors == null) {
      if (type === 'start') return renderStartIcon()
      if (type === 'end') return renderEndIcon()
      return <></>
    }

    const errorList = props.errors[props.name]
    const hasErrors = _.find(errorList, 'error')

    //Renders the end icon, if present.
    if (hasErrors == null) {
      if (type === 'start') return renderStartIcon()
      if (type === 'end') return renderEndIcon()
      return <></>
    }

    //Renders the error icon.
    return renderErrorIcon()

    function renderStartIcon(): ReactNode {
      if (props.type === 'date') return <div className={clsx(classes.startIconContainer, classes.disabledIcon)}><DateRangeIcon /></div>
      if (props.startIcon == null) return <></>
      return <div className={classes.startIconContainer}>{props.startIcon}</div>
    }
    function renderEndIcon(): ReactNode {
      if (props.endIcon == null) return <></>
      return <div className={classes.endIconContainer}>{props.endIcon}</div>
    }
    function renderErrorIcon(): ReactNode {
      let errorNodes: ReactNode[] = []
      errorList.forEach(e => {
        if (e.error === true) errorNodes.push(<div>• {e.errorMessage}</div>)
      })

      return <div className={classes.endIconContainer}><Tooltip title={<>{errorNodes.map(e => e)}</>} placement="top-end"><WarningIcon className={classes.errorIcon} /></Tooltip></div>
    }
  }

  let fieldContainerSizeClass = ''
  if (props.size === 'small') fieldContainerSizeClass = classes.fieldContainerSmall

  return (
    <div className={clsx(props.className, classes.container)}>
      <div className={layoutClass}>

        {(props.label != null || props.link != null) &&
          <div className={clsx(classes.labelContainer)}>
            {props.label != null && <Typography className={classes.label} variant="body1">{props.label}</Typography>}
            {props.link != null && <Link component={RouterLink} to={props.link.url} className={classes.link} variant="body1">{props.link.text}</Link>}
          </div>
        }

        <div className={props.layout !== 'horizontal' && props.type === 'boolean' ? clsx(classes.fieldContainer, fieldContainerSizeClass, classes.fieldContainerLeftAligned) : clsx(classes.fieldContainer, fieldContainerSizeClass)}>
          {renderIcon('start')}
          {renderField()}
          {renderIcon('end')}
        </div>
      </div>
      <div className={clsx(classes.descriptionContainer)}>
        {props.description != null && (<Typography className={classes.description} variant="body2">{props.description}</Typography>)}
      </div>
    </div>
  )
}

export default CardField
