import { Value, Color } from 'theme/style'

export default {
  root: {
    borderRadius: Value.BorderRadius_Button,
    transition: 'none'
  },
  contained: {
    padding: '10px 15px',
    textTransform: 'uppercase',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
  },
  containedPrimary: {
    color: Color.White
  }
};
