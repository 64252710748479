import executeRequest, { RequestOutput } from 'app/api'
import { APIPaginatable, APIPaginatableResponse, APISortable, APISearchable } from 'app/api/types'



//ENDPOINTS

enum APIEndpoint {
}



//API TYPES

export type API_DM = {
  id: number
  fullname?: string
  email?: string
  phone?: string,
}



//REQUESTS
