import palette from 'theme/config/palette';
import typography from 'theme/config/typography';

export default {
  root: {
    height: '100%',
    '&::before': {
      display: 'none'
    },
    '&::after': {
      display: 'none'
    },
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none",
      margin: 80
    },
  },
  input: {
    ...typography.body1,
    color: palette.text.primary,
    padding: '14px',
    '&::placeholder': {
      opacity: 1,
      color: palette.text.secondary,
    },
  },
  multiline: {
    padding: 0
  }
};
