import { Color, FontFamily, FontSize, FontWeight, Value } from 'theme/style'
import spacing from 'theme/config/spacing'

export default {
  root: {
  },
  endAdornment: {
    '& svg': {
      fontSize: '2.2rem',
      color: Color.TextSecondary
    }
  }
};
