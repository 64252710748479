import React, { useEffect } from 'react'
import { Input, InputBaseProps, Button, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { Color, FontWeight, Value } from 'theme/style'
import { config } from 'app/config'
import clsx from 'clsx'
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    position: 'relative',
    display: 'flex',
  },
  input: {
    flex: 1,
    '& input': {
      textAlign: 'left',
      padding: theme.spacing(0, 1.5),
      height: '45px',
      backgroundColor: Color.Background,
      borderRadius: Value.BorderRadius_Field,
    },
  },
  actionInput: {
    '& input': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0
    }
  },
  actionsContainer: {
    position: 'relative',
    backgroundColor: Color.Background,
    borderRadius: Value.BorderRadius_Field,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    right: 0,
    top: 0,
    '& button': {
      height: '45px',
      width: '45px',
    }
  },
}))

interface ButtonFieldProps extends InputBaseProps {
  enableEdit?: boolean
  enableClear?: boolean
  actions?: React.ReactNode
  onClick?: () => void
  onRemove?: () => void
  onUpdate?: (name: string, value: any) => void
  onConfirm?: (name: string, value: any) => void
}
const ButtonField = ({ ...props }: ButtonFieldProps) => {
  const classes = useStyles()

  const hasActions = props.enableEdit === true
    || props.enableClear === true
    || props.actions != null


  //CLICK

  function click() {
    if (props.onClick != null) props.onClick()
  }

  function disabledClick() {
  }

  function remove() {
    if (props.onRemove != null) props.onRemove()
  }



  //RENDER

  return (
    <div className={classes.buttonContainer}>
      <Input {...props} disabled className={hasActions === false ? classes.input : clsx(classes.input, classes.actionInput)} onClick={disabledClick}></Input>
      <div className={classes.actionsContainer}>
        {props.enableEdit === true && <IconButton onClick={click}><EditIcon /></IconButton>}
        {props.enableClear === true && <IconButton onClick={remove}><ClearIcon /></IconButton>}
        {props.actions}
      </div>
    </div>
  )
}

export default ButtonField