import createBreakpoints from '@material-ui/core/styles/createBreakpoints'

const breakpoints = createBreakpoints({})

export default {
  root: {
    padding: '20px',
    [breakpoints.down('sm')]: {
      padding: '8px 20px',
    },
  },
};
