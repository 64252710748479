import { getToken } from 'app/utils'


//Defines types used to make requests and handle responses.

type Request<X, Y, Z> = {
	endpoint: string,
	params?: X,
	output?: RequestOutput<Y, Z>,
	headers?: any
}

export type RequestOutput<Y, Z> = {
	response?: (data: Y) => void,
	error?: (error: RequestError | Z, message: string) => void
}


//Request errors.
//Defines the different types of error that can occur if the API is not available.

export enum RequestError {
	APIUnavailable = 'API with the provided name doesn\'t exist',
	GenericError = 'API responded with an error',
}


//Request config.
//Create the axios object to make HTTP requests and sets common configuration.

export default async function executeRequest(request: Request<any, any, any>) {
	const axios = require('axios')
	const baseURL = process.env.REACT_APP_API_BASEURL
	const authToken = getToken()

	const instance = axios.create({
		baseURL: baseURL,
	})
	const config = {
		headers: {
			'Content-type': 'application/x-www-form-urlencoded',
			'X-Auth-Token': authToken
		}
	}

	try {
		const response = await instance.post(request.endpoint, request.params, config)

		if (response.data.error) {
			console.error('Response error: ', response.data.error)
			if (request.output?.error) request.output?.error(response.data, response.data.error)
			return
		}

		if (response.data) {
			console.log('Response: ', response)
			if (request.output?.response) request.output?.response(response.data)
		}
	}
	catch (error) {
		console.error('Request error: ', error)
		if (request.output?.error) request.output?.error(RequestError.APIUnavailable, error as any)
	}
}