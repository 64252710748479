import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';
import 'assets/stylesheets/quill.css';
import clsx from 'clsx';
import { config } from 'app/config'

const useStyles = makeStyles((theme) => ({
  richTextEditor: {
    position: 'relative',
    height: '100%',
  },
}))

export enum RichTextFieldToolbarType {
  Standard,
  RichText,
  Media
}

interface RichTextFieldProps {
  className?: string
  value: string
  placeholder?: string
  name: string
  toolbarType?: RichTextFieldToolbarType
  onChangeCallback?: (value: string, name?: string) => void
  onBlurCallback?: (name?: string) => void
  onUpdate?: (name: string, value: any) => void
  onConfirm?: (name: string, value: any) => void
}
const RichTextField = ({ ...props }: RichTextFieldProps) => {
  const classes = useStyles()
  const [editorValue, setEditorValue] = useState(props.value)


  //Toolbar.
  let editorToolbar = []

  if (props.toolbarType == null || props.toolbarType === RichTextFieldToolbarType.Standard) {
    editorToolbar.push(['bold', 'italic', 'underline'])
  }
  if (props.toolbarType === RichTextFieldToolbarType.RichText) {
    editorToolbar.push(['bold', 'italic', 'underline', 'blockquote'])
    editorToolbar.push([{ 'list': 'ordered' }, { 'list': 'bullet' }])
    editorToolbar.push(['link'])
  }
  if (props.toolbarType === RichTextFieldToolbarType.Media) {
    editorToolbar.push([{ 'header': [2, 3, 4, false] }])
    editorToolbar.push(['bold', 'italic', 'underline', 'blockquote'])
    editorToolbar.push([{ 'list': 'ordered' }, { 'list': 'bullet' }])
    editorToolbar.push(['link', 'image', 'video'])
  }

  const editorModules = {
    toolbar: editorToolbar
  }



  //Value updates.
  useEffect(() => {
    setEditorValue(props.value)
  }, [props.value])

  function handleEditorChange(value: string, name?: string) {
    setEditorValue(value)
    if (props.onChangeCallback != null) props.onChangeCallback(value, name)
    if (props.onUpdate != null) props.onUpdate(props.name, value)

  }

  function handleEditorBlur(name?: string) {
    if (props.onBlurCallback != null) props.onBlurCallback(name)
  }


  //Render.

  return (
    <div className={classes.richTextEditor} onBlur={v => handleEditorBlur(props.name)}>
      <ReactQuill
        className={clsx(props.className)}
        placeholder={props.placeholder ?? ''}
        value={editorValue}
        modules={editorModules}
        onChange={v => handleEditorChange(v, props.name)}
      />
    </div>
  )
}

export default RichTextField