import PageIcon from '@material-ui/icons/Description'
import CommonTextIcon from '@material-ui/icons/TextFields'
import CreateIcon from '@material-ui/icons/Create'
import TagIcon from '@material-ui/icons/LocalOffer'

export default [
  {
    title: 'sidebar.title.dashboard', pages: [
      { title: 'sidebar.item.dashboard', icon: PageIcon, href: '/dashboard' },
    ]
  },
]