import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Typography } from '@material-ui/core';
import { updateFieldTranslations, UpdateFieldTranslationsRequestParams } from 'app/api';
import { useLocalization } from 'components';
import { Field, FieldGroup, FieldTranslation } from 'app/entities/types';
import { toast } from 'app/utils';
import { isFieldGroup } from 'app/entities/methods';
import clone from 'lodash/cloneDeep';
import find from 'lodash/find';
import React, { useEffect, useState } from 'react';
import { Color } from 'theme/style';
import { EditableItem } from './../../types';
import { FieldGroupInput, FieldInput } from './components';

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: '700px',
    maxWidth: '95%'
  },
  input: {
    width: '100%',
    padding: 0,
    height: '2rem',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  ctaError: {
    color: Color.Error
  },
  ctaSuccess: {
    backgroundColor: Color.Success,
    color: Color.White
  }
}))

type FieldValueDialogProps = {
  open: boolean
  item: FieldGroup | Field
  onClose: () => void
  onSaveCallback: () => void
}

const FieldValueDialog = ({ open, item, onClose, onSaveCallback }: FieldValueDialogProps) => {
  const classes = useStyles()
  const { t } = useLocalization()

  const [errors, setErrors] = useState<{ id: number, hasError: boolean }[]>([])
  const [shouldAllowSave, setShouldAllowSave] = useState(true)

  const [object, setObject] = useState<EditableItem | null>(null)
  const [translationData] = useState<FieldTranslation[]>([])

  useEffect(() => {
    if (open === false) return
    setObject(item)

    if (object == null) return

    if (isFieldGroup(object) === true) {
    }
    else {
      setErrors([
        ...errors, {
          id: object.id,
          hasError: false
        }
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const updateTranslationData = (data: FieldTranslation) => {
    if (object == null) return

    let newData = clone(translationData)
    const element = find(newData, ['id', data.id])
    if (element == null) newData.push(data)
    else element.value = data.value

    Object.assign(translationData, newData)
  }

  const checkSaveStatus = (field: Field | null, hasError: boolean) => {
    if (field && isFieldGroup(object) === true) {
      let temp = errors.filter(e => e.id === field.id)
      if (temp.length === 0) {
        setErrors([...errors, { id: field.id, hasError: hasError }])
      }
      else {
        const error = temp[0]
        error.hasError = hasError
      }
      temp = errors.filter(e => e.hasError === true)
      setShouldAllowSave(temp.length === 0)
    }
    else {
      setShouldAllowSave(!hasError)
    }
  }

  function save() {
    function update() {
      const encode = (): UpdateFieldTranslationsRequestParams => {
        return {
          translations: {
            ...translationData
          }
        }
      }

      updateFieldTranslations(encode(), {
        response(data) {
          toast.success("Valori aggiornati")
          onSaveCallback()
          onClose()
        },
        error(error, message) {
          toast.error(message)
        }
      })
    }

    update()
  }

  const renderContent = () => {
    if (open === false) return <></>
    if (object == null) return <></>

    const renderFieldGroup = (fieldGroup: FieldGroup) => {
      return <FieldGroupInput fieldGroup={fieldGroup} updateTranslationDataCallback={updateTranslationData} checkSaveStatusCallback={checkSaveStatus} />
    }

    const renderField = (field: Field) => {
      return <FieldInput field={field} updateTranslationDataCallback={updateTranslationData} checkSaveStatusCallback={checkSaveStatus} />
    }

    if (isFieldGroup(object) === true) return renderFieldGroup(object as unknown as FieldGroup)
    else return renderField(object as unknown as Field)
  }

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.dialog }}>
      <DialogTitle>
        <Typography component="h2" variant="h2">{t('common.page.tab.page-content.dialog.title', { tags: { 'ITEM': object?.name } })}</Typography>
        {object?.description != null && object?.description !== '' && <Typography component="p" variant="body1">{object?.description}</Typography>}
      </DialogTitle>
      <DialogContent>
        {renderContent()}
      </DialogContent>
      <DialogActions className={classes.actions}>
        {shouldAllowSave === true && (<Button className={classes.ctaSuccess} variant="contained" onClick={save}>{t('common.save')}</Button>)}
      </DialogActions>
    </Dialog>
  )
}

export default FieldValueDialog
