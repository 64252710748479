import executeRequest, { RequestOutput } from 'app/api'
import { APIPaginatable, APIPaginatableResponse, APISortable, APISearchable } from 'app/api/types'



//ENDPOINTS

enum APIEndpoint {
  GetAllModels = 'api/projectCardioModels'
}



//API TYPES

export type API_Model = {
  id: number
  name?: string
  keyword?: string
}



//REQUESTS

export interface GetAllModelsRequestParams extends APIPaginatable, APISortable, APISearchable {
}
export interface GetAllModelsResponse extends APIPaginatableResponse {
  projectCardioModels: API_Model[]
}
enum GetAllModelsResponseError {
}
export async function getAllModels(params: GetAllModelsRequestParams, output?: RequestOutput<GetAllModelsResponse, GetAllModelsResponseError>) {
  executeRequest({
    params: params,
    endpoint: APIEndpoint.GetAllModels,
    output: output,
  })
}
