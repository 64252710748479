import executeRequest, { RequestOutput } from 'app/api'

enum APIEndpoint {
  GetAllCurrencies = 'api/currencies',
}



export type APICurrency = {
  id: number
  name: string
  code: string
  htmlSymbol: string
}



//Retrieves the languages in the CMS.
//Mainly used on application first loading, the response data is saved in the store for further use.

export type GetAllCurrenciesResponse = {
  currencies: APICurrency[]
}

enum GetAllCurrenciesResponseError {
}

export async function getAllCurrencies(output?: RequestOutput<GetAllCurrenciesResponse, GetAllCurrenciesResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetAllCurrencies,
    output: output,
  })
}
