import React from 'react'
import { Select, SelectProps, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { Color, Value } from 'theme/style'
import ClearIcon from '@material-ui/icons/Clear'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
  },
  input: {
    flex: 1,
    textAlign: 'left',
    padding: theme.spacing(0, 1.5),
    height: '45px',
    backgroundColor: Color.Background,
    // border: '2px solid transparent',
    // borderColor: '#E8E8E8',
    borderRadius: Value.BorderRadius_Field,
    '& >div': {
      width: '100% !important',
      paddingLeft: 0
    },
    '& >svg': {
      right: '10px'
    }
  },
  clear: {
    minWidth: '45px',
    width: '45px',
    height: '45px',
    marginLeft: theme.spacing(1),
    '& svg': {
      fontSize: '20px',
      color: Color.TextSecondary
    }
  }
}))

interface SelectFieldProps extends SelectProps {
  enableClear?: boolean
  onUpdate?: (name: string, value: any) => void
  onConfirm?: (name: string, value: any) => void
}
const SelectField = ({ ...props }: SelectFieldProps) => {
  const classes = useStyles()

  const enableClearValue = props.enableClear ?? false


  //FIELD CHANGES

  function updateField(event: any) {
    const target = event.target as HTMLInputElement
    const name = target.name as string
    const value = target.value

    if (props.onUpdate == null) return
    props.onUpdate(name, value)
  }



  //EVENTS

  function remove() {
    if (props.name == null) return
    if (props.onUpdate != null) props.onUpdate(props.name, null)
  }



  //RENDER

  return (
    <div className={classes.container}>
      <Select {...props} disableUnderline className={classes.input} onChange={updateField}>
        {props.children}
      </Select>
      {enableClearValue === true && <Button className={classes.clear} onClick={remove}><ClearIcon /></Button>}
    </div>
  )
}

export default SelectField
