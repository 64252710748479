export default {
  root: {
  },
  elevation1: {
    boxShadow: '0 8px 12px 0 rgba(0,0,0,0.08)'
  },
  elevation8: {
    boxShadow: '0 8px 12px 0 rgba(0,0,0,0.08)'
  },
};
