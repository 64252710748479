import React from 'react'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { SnackbarProvider } from 'notistack'
import { SnackbarUtilsConfigurator } from 'app/utils'
import { Color, FontSize, FontWeight } from 'theme/style'

const useStyles = makeStyles((theme) => ({
  default: {
    width: '100vw',
    borderRadius: 0,
    fontSize: FontSize.Body1,
    fontWeight: FontWeight.SemiBold,
    boxShadow: 'none',
    marginBottom: '3px !important'
  },
  production: {
    marginBottom: '-20px !important'
  },
  success: {
    backgroundColor: Color.Success + ' !important',
    color: Color.White + ' !important'
  },
  error: {
    backgroundColor: Color.Error + ' !important',
    color: Color.White + ' !important'
  },
  warning: {
    backgroundColor: Color.Warning + ' !important',
    color: Color.White + ' !important'
  },
  info: {
    backgroundColor: Color.Primary + ' !important',
    color: Color.White + ' !important'
  },
}))

interface ToastProviderProps {
}

const ToastProvider = ({ ...props }: ToastProviderProps) => {
  const classes = useStyles()

  const productionClass: any = process.env.REACT_APP_ENVNAME === 'production' && classes.production

  return (
    <SnackbarProvider
      maxSnack={1}
      hideIconVariant
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transitionDuration={{ enter: 150, exit: 150 }}
      classes={{
        variantSuccess: clsx(classes.default, classes.success, productionClass),
        variantError: clsx(classes.default, classes.error, productionClass),
        variantWarning: clsx(classes.default, classes.warning, productionClass),
        variantInfo: clsx(classes.default, classes.info, productionClass),
      }}
    >
      <SnackbarUtilsConfigurator />
    </SnackbarProvider>
  )
}

export default ToastProvider