import spacing from 'theme/config/spacing'

export default {
  tabs: {
    '& button': {
      marginRight: 0,
      '&:hover': {
        borderRadius: 0,
        backgroundColor: 'rgba(255,255,255,0.2)'
      }
    }
  },
};
