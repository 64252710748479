
import { User, Language, PageType, FieldGroupType, FieldType, UserRole, MediaLibraryFolder } from 'app/entities/types'
import { SessionPersistedListParameters } from 'app/session/reducers'

export enum SessionActionType {
  Login,
  Logout,
  UpdateUser,
  UpdateUserPermissions,
  UpdateLanguages,
  UpdateMediaLibraryFolders,
  UpdatePageTypes,
  UpdateFieldGroupTypes,
  UpdateFieldTypes,
  UpdatePersistedListParameters,
  ResetPersistedListParameters,
  UpdateSelectedLanguage,
}
export type SessionAction = Login | Logout | UpdateUser | UpdateUserPermissions | UpdateLanguages | UpdateMediaLibraryFolders | UpdatePageTypes | UpdateFieldGroupTypes | UpdateFieldTypes | UpdatePersistedListParameters | ResetPersistedListParameters | UpdateSelectedLanguage


export type Login = {
  type: typeof SessionActionType.Login
  data: User
}
export function login(data: User): Login {
  return {
    type: SessionActionType.Login,
    data: data
  }
}

export type Logout = {
  type: typeof SessionActionType.Logout
}
export function logout(): Logout {
  return {
    type: SessionActionType.Logout,
  }
}



export type UpdateUser = {
  type: typeof SessionActionType.UpdateUser
  data: User
}
export function updateUser(data: User): UpdateUser {
  return {
    type: SessionActionType.UpdateUser,
    data: data
  }
}


export type UpdateUserPermissions = {
  type: typeof SessionActionType.UpdateUserPermissions
  data: UserRole
}
export function updateUserPermissions(data: UserRole): UpdateUserPermissions {
  return {
    type: SessionActionType.UpdateUserPermissions,
    data: data
  }
}



export type UpdateLanguages = {
  type: typeof SessionActionType.UpdateLanguages
  data: Language[]
}
export function updateLanguages(data: Language[]): UpdateLanguages {
  return {
    type: SessionActionType.UpdateLanguages,
    data: data
  }
}



export type UpdateMediaLibraryFolders = {
  type: typeof SessionActionType.UpdateMediaLibraryFolders
  data: MediaLibraryFolder[]
}
export function updateMediaLibraryFolders(data: MediaLibraryFolder[]): UpdateMediaLibraryFolders {
  return {
    type: SessionActionType.UpdateMediaLibraryFolders,
    data: data
  }
}


export type UpdatePageTypes = {
  type: typeof SessionActionType.UpdatePageTypes
  data: PageType[]
}
export function updatePageTypes(data: PageType[]): UpdatePageTypes {
  return {
    type: SessionActionType.UpdatePageTypes,
    data: data
  }
}



export type UpdateFieldGroupTypes = {
  type: typeof SessionActionType.UpdateFieldGroupTypes
  data: FieldGroupType[]
}
export function updateFieldGroupTypes(data: FieldGroupType[]): UpdateFieldGroupTypes {
  return {
    type: SessionActionType.UpdateFieldGroupTypes,
    data: data
  }
}


export type UpdateFieldTypes = {
  type: typeof SessionActionType.UpdateFieldTypes
  data: FieldType[]
}
export function updateFieldTypes(data: FieldType[]): UpdateFieldTypes {
  return {
    type: SessionActionType.UpdateFieldTypes,
    data: data
  }
}


export type UpdatePersistedListParameters = {
  type: typeof SessionActionType.UpdatePersistedListParameters
  data: SessionPersistedListParameters
}
export function updatePersistedListParameters(data: SessionPersistedListParameters): UpdatePersistedListParameters {
  return {
    type: SessionActionType.UpdatePersistedListParameters,
    data: data
  }
}

export type ResetPersistedListParameters = {
  type: typeof SessionActionType.ResetPersistedListParameters
  data?: string
}
export function resetPersistedListParameters(data?: string): ResetPersistedListParameters {
  return {
    type: SessionActionType.ResetPersistedListParameters,
    data: data
  }
}


export type UpdateSelectedLanguage = {
  type: typeof SessionActionType.UpdateSelectedLanguage
  data: Language
}
export function updateSelectedLanguage(data: Language): UpdateSelectedLanguage {
  return {
    type: SessionActionType.UpdateSelectedLanguage,
    data: data
  }
}
