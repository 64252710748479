import executeRequest, { RequestOutput } from 'app/api'
import { APIPaginatable, APIPaginatableResponse, APISortable, APISearchable } from 'app/api/types'



//ENDPOINTS

enum APIEndpoint {
  GetAllZentivaContacts = 'api/zentivaContacts',
  GetZentivaContactById = 'api/zentivaContacts/getById',
  CreateZentivaContact = 'api/zentivaContacts/create',
  UpdateZentivaContact = 'api/zentivaContacts/update',
  DeleteZentivaContact = 'api/zentivaContacts/delete',
}



//API TYPES

export type API_ZentivaContact = {
  id: number
  fullname?: string
  email?: string
  phone?: string,
}



//REQUESTS

export interface GetAllZentivaContactsRequestParams extends APIPaginatable, APISortable, APISearchable {
  trainingStatusKeyword?: string
  assigned?: boolean
}
export interface GetAllZentivaContactsResponse extends APIPaginatableResponse {
  zentivaContacts: API_ZentivaContact[]
}
enum GetAllZentivaContactsResponseError {
}
export async function getAllZentivaContacts(params: GetAllZentivaContactsRequestParams, output?: RequestOutput<GetAllZentivaContactsResponse, GetAllZentivaContactsResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetAllZentivaContacts,
    params: params,
    output: output,
  })
}



export type GetZentivaContactRequestParams = {
  id: number
}
export type GetZentivaContactResponse = {
  zentivaContact: API_ZentivaContact
}
enum GetZentivaContactResponseError {
}
export async function getZentivaContact(params: GetZentivaContactRequestParams, output?: RequestOutput<GetZentivaContactResponse, GetZentivaContactResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetZentivaContactById,
    params: params,
    output: output,
  })
}



export type CreateZentivaContactResponse = {
  zentivaContact: API_ZentivaContact
}
enum CreateZentivaContactResponseError {
}
export async function createZentivaContact(output?: RequestOutput<CreateZentivaContactResponse, CreateZentivaContactResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.CreateZentivaContact,
    output: output,
  })
}


export type UpdateZentivaContactRequestParams = API_ZentivaContact
export type UpdateZentivaContactResponse = {
}
enum UpdateZentivaContactResponseError {
}
export async function updateZentivaContact(params: UpdateZentivaContactRequestParams, output?: RequestOutput<UpdateZentivaContactResponse, UpdateZentivaContactResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateZentivaContact,
    params: params,
    output: output,
  })
}



export type DeleteZentivaContactRequestParams = {
  id: number
}
export type DeleteZentivaContactResponse = {
}
enum DeleteZentivaContactResponseError {
}
export async function deleteZentivaContact(params: DeleteZentivaContactRequestParams, output?: RequestOutput<DeleteZentivaContactResponse, DeleteZentivaContactResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.DeleteZentivaContact,
    params: params,
    output: output,
  })
}