import { Tabs, Tab, makeStyles } from '@material-ui/core';
import { Language } from 'app/entities/types';
import { Page, PageProps, LanguageSelector, useLocalization } from 'components';
import React, { ReactNode, useState, useEffect } from 'react';
import { useSelector, useDispatch, useStore } from 'react-redux'
import { RootState } from 'app/session/store'
import _ from "lodash"
import { updateSelectedLanguage } from 'app/session/actions'
import { Redirect } from "react-router-dom";
import { useRouter } from 'app/utils';

const useStyles = makeStyles((theme) => ({

}))


export type PageEntityDetailsRefreshableProps = {
  refreshObject?: () => void
}

interface PageEntityDetailsProps extends PageProps {

}

const PageEntityDetails = ({ ...props }: PageEntityDetailsProps) => {
  const classes = useStyles()
  const { t } = useLocalization()
  const session = useSelector((state: RootState) => state.session)
  const dispatch = useDispatch()
  const store = useStore()
  const router = useRouter()



  //RENDER

  //If a default tab is provided and a selected tab is not, automatically redirect to the specific tab.
  if (props.currentRoute != null && props.defaultTab != null && props.selectedTab == null) {
    return <Redirect to={props.currentRoute + '/' + props.defaultTab} />
  }

  //If the selected tab is not a valid one redirect to an error page. 
  if (props.tabs != null && props.tabs?.find(t => t.key === props.selectedTab) == null) {
    return <Redirect to="/errors/404" />
  }


  return (
    <Page {...props}
      tabs={props.tabs} defaultTab={props.defaultTab} selectedTab={props.selectedTab} updateTab={props.updateTab}
      language={props.language} updateLanguage={props.updateLanguage}
    >
      {props.children}
    </Page>
  )
}

export default PageEntityDetails