import { Grid } from '@material-ui/core';
import { Language, Page } from 'app/entities/types';
import { MediaLibraryFolderValue } from 'app/values';
import { PageEntityDetailsRefreshableProps } from 'components';
import { PageData, PageStatus, PageCoverImage } from './components';
import React from 'react';

interface PageEntityTabDataProps extends PageEntityDetailsRefreshableProps {
  object: Page
  language: Language
  pageId: number
  parents?: Page[] | null
  mediaLibraryFolder?: MediaLibraryFolderValue
}
const PageEntityTabData = ({ ...props }: PageEntityTabDataProps) => {
  return (
    <>
      <Grid item xl={3} md={6} xs={12}>
        <PageData object={props.object} pageId={props.pageId} language={props.language} refreshObject={props.refreshObject} parents={props.parents} />
      </Grid>
      <Grid item xl={3} md={6} xs={12}>
        <PageStatus object={props.object} pageId={props.pageId} refreshObject={props.refreshObject} />
      </Grid>
      <Grid item xl={3} md={6} xs={12}>
        <PageCoverImage object={props.object} pageId={props.pageId} refreshObject={props.refreshObject} mediaLibraryFolder={props.mediaLibraryFolder} />
      </Grid>
    </>
  )
}

export default PageEntityTabData
