import executeRequest, { RequestOutput } from 'app/api'

enum APIEndpoint {
  GetAllUserPermissions = 'api/permissions',
  GetUserPermissionById = 'api/permissions/getById',
  CreateUserPermission = 'api/permissions/create',
  UpdateUserPermission = 'api/permissions/update',
  DeleteUserPermission = 'api/permissions/delete',
}


//Retrieves the languages in the CMS.
//Mainly used on application first loading, the response data is saved in the store for further use.

export type GetUserPermissionsResponse = {
  permissions: {
    id: number
    name: string
    keyword: string
  }[]
}

enum GetUserPermissionsResponseError {
}

export async function getUserPermissions(output?: RequestOutput<GetUserPermissionsResponse, GetUserPermissionsResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetAllUserPermissions,
    output: output,
  })
}



export type GetUserPermissionRequestParams = {
  id: number
}
export type GetUserPermissionResponse = {
  permission: {
    id: number,
    name: string,
    keyword: string
  }
}

enum GetUserPermissionResponseError {
}

export async function getUserPermission(params: GetUserPermissionRequestParams, output?: RequestOutput<GetUserPermissionResponse, GetUserPermissionResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetUserPermissionById,
    params: params,
    output: output,
  })
}



export type CreateUserPermissionRequestParams = {
  name: string
  keyword: string
}

type CreateUserPermissionResponse = {
  permission: {
    id: number
  }
}

enum CreateUserPermissionResponseError {
}

export async function createUserPermission(params: CreateUserPermissionRequestParams, output?: RequestOutput<CreateUserPermissionResponse, CreateUserPermissionResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.CreateUserPermission,
    params: params,
    output: output,
  })
}



export type UpdateUserPermissionRequestParams = {
  id: number
  name: string
  keyword: string
}

type UpdateUserPermissionResponse = {
}

enum UpdateUserPermissionResponseError {
}

export async function updateUserPermission(params: UpdateUserPermissionRequestParams, output?: RequestOutput<UpdateUserPermissionResponse, UpdateUserPermissionResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateUserPermission,
    params: params,
    output: output,
  })
}



export type DeleteUserPermissionRequestParams = {
  id: number
}

type DeleteUserPermissionResponse = {
}

enum DeleteUserPermissionResponseError {
}

export async function deleteUserPermission(params: DeleteUserPermissionRequestParams, output?: RequestOutput<DeleteUserPermissionResponse, DeleteUserPermissionResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.DeleteUserPermission,
    params: params,
    output: output,
  })
}
